import React from 'react';

function PixelatedDick() {
      return (
            <div className="pixelated-penis-with-spinning-text">
                  <div className="spinning-text-group">
                        <svg className="spinning-text" width="251" height="251" viewBox="0 0 251 251" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <svg width="252" height="252" viewBox="0 0 252 252" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0)">
                                          <path d="M69.63 21.48C71.71 20.87 72.87 21.48 73.49 22.54C74.38 24.14 73.26 25.85 71.2 26.99C67.89 28.84 65.83 27.53 65.14 26.41L66.36 25.17C66.36 25.17 67.42 27.68 70.57 25.93C71.94 25.16 72.44 24.13 72.05 23.43C71.66 22.73 70.78 22.75 69.37 23.15L67.04 23.82C65.4 24.29 64.24 23.88 63.67 22.82C62.96 21.54 63.35 19.82 65.73 18.48C69.45 16.41 70.94 18.89 70.94 18.89L69.7 20.1C69.7 20.1 68.82 18.17 66.27 19.6C65.12 20.24 64.67 21.15 65.07 21.87C65.47 22.59 66.15 22.49 67.2 22.17L69.63 21.48Z" fill="black" />
                                          <path d="M79.31 22.93C78.8714 23.1646 78.3889 23.3058 77.893 23.3447C77.3971 23.3835 76.8985 23.3191 76.4287 23.1556C75.9589 22.9922 75.5281 22.7331 75.1635 22.3948C74.7988 22.0565 74.5082 21.6462 74.31 21.19C74.0862 20.7418 73.9606 20.251 73.9416 19.7503C73.9227 19.2497 74.0108 18.7508 74.2001 18.287C74.3894 17.8231 74.6756 17.4051 75.0394 17.0607C75.4033 16.7163 75.8365 16.4536 76.31 16.29C79.87 14.68 81.13 18.29 81.13 18.29L75.79 20.57C75.9047 20.8776 76.0833 21.1573 76.3141 21.3907C76.5449 21.6241 76.8226 21.8058 77.1288 21.924C77.435 22.0421 77.7628 22.094 78.0906 22.076C78.4183 22.0581 78.7385 21.9709 79.03 21.82C79.2705 21.7357 79.4908 21.6024 79.677 21.4286C79.8633 21.2547 80.0114 21.0441 80.1119 20.81C80.2125 20.576 80.2634 20.3235 80.2614 20.0688C80.2593 19.814 80.2044 19.5624 80.1 19.33L81.7 19.1C81.7 19.1 82.32 21.57 79.31 22.93ZM76.68 17.37C76.2053 17.5703 75.8229 17.9411 75.6081 18.4095C75.3934 18.8778 75.362 19.4096 75.52 19.9L79.28 18.13C79.1597 17.9089 78.9969 17.7137 78.801 17.5557C78.605 17.3977 78.3797 17.28 78.1381 17.2094C77.8964 17.1387 77.6432 17.1166 77.393 17.1441C77.1428 17.1717 76.9005 17.2485 76.68 17.37V17.37Z" fill="black" />
                                          <path d="M90 12.23C89 12.54 88.75 13.03 88.9 13.51C89.05 13.99 89.54 14.07 90.5 13.94L92.21 13.71C93.77 13.51 94.54 14.04 94.82 14.91C95.22 16.19 94.15 17.4 92.52 17.91C89.05 19 87.96 16.73 87.96 16.73L89.3 15.84C89.3 15.84 89.63 17.65 92.2 16.84C93.09 16.56 93.48 16 93.33 15.5C93.18 15 92.75 14.83 91.64 14.97L90.1 15.17C88.54 15.37 87.76 14.81 87.51 14.01C87.12 12.76 88.13 11.64 89.69 11.15C92.69 10.15 93.54 12.15 93.54 12.15L92.19 13.05C92.19 13.05 91.91 11.63 90 12.23Z" fill="black" />
                                          <path d="M94.34 9.94001L95.91 9.55L95.53 8.02L96.69 6.71L97.3 9.21L100.07 8.53001L100.3 9.44001L97.53 10.12L98.36 13.51C98.62 14.56 99.14 14.94 99.91 14.75C100.238 14.6801 100.537 14.5145 100.77 14.2741C101.003 14.0338 101.16 13.7295 101.22 13.4L101.77 14.4C101.596 14.7818 101.334 15.1168 101.006 15.3776C100.677 15.6384 100.291 15.8174 99.88 15.9C99.5628 16.0147 99.2234 16.0549 98.8882 16.0176C98.553 15.9802 98.2308 15.8663 97.9466 15.6846C97.6625 15.5029 97.4239 15.2582 97.2493 14.9696C97.0748 14.681 96.9689 14.3561 96.94 14.02L96.07 10.48L94.57 10.85L94.34 9.94001Z" fill="black" />
                                          <path d="M109.39 12.89C109.576 12.8433 109.746 12.747 109.881 12.6114C110.017 12.4758 110.113 12.306 110.16 12.12L110.65 13.12C110.486 13.3726 110.266 13.5847 110.008 13.7406C109.75 13.8964 109.46 13.992 109.16 14.02C108.953 14.0773 108.736 14.0873 108.525 14.0492C108.314 14.0112 108.114 13.9261 107.94 13.8003C107.766 13.6745 107.623 13.5111 107.521 13.3224C107.419 13.1336 107.36 12.9243 107.35 12.71L107.14 11.54C107.14 11.54 106.65 14.47 104.6 14.83C104.395 14.8861 104.18 14.8987 103.97 14.8672C103.759 14.8356 103.558 14.7604 103.378 14.6466C103.198 14.5327 103.044 14.3826 102.926 14.2058C102.807 14.0291 102.727 13.8295 102.69 13.62C102.49 12.47 103.21 10.71 106.81 9.61999C106.793 9.42143 106.732 9.22915 106.632 9.05713C106.531 8.8851 106.393 8.73766 106.229 8.62558C106.064 8.51349 105.876 8.43958 105.679 8.40921C105.482 8.37883 105.281 8.39275 105.09 8.44998C104.66 8.51378 104.262 8.71627 103.957 9.02667C103.652 9.33707 103.456 9.73846 103.4 10.17L101.8 10C101.963 9.29459 102.347 8.65948 102.895 8.18664C103.444 7.7138 104.128 7.42777 104.85 7.36999C106.85 6.99999 108.08 7.91999 108.29 9.36999L108.72 12.25C108.79 12.71 109 12.96 109.39 12.89ZM104.98 13.67C106.28 13.44 107.07 11.04 106.98 10.53L106.9 10.1C104.28 11.29 104.11 12.47 104.22 13.1C104.227 13.1898 104.252 13.2771 104.296 13.3561C104.339 13.4351 104.398 13.5039 104.47 13.5579C104.542 13.6119 104.625 13.6499 104.713 13.6692C104.801 13.6886 104.892 13.6888 104.98 13.67V13.67Z" fill="black" />
                                          <path d="M110.57 3.91001L112.06 3.72999L112.21 4.91999L110.72 5.10001L110.57 3.91001ZM110.91 6.66999L112.4 6.49L113.24 13.38L111.75 13.56L110.91 6.66999Z" fill="black" />
                                          <path d="M120.3 3.21C120.802 3.18247 121.3 3.11223 121.79 3.00001L122.2 12.71L120.7 12.77L120.3 3.21Z" fill="black" />
                                          <path d="M127.8 12.82C127.302 12.8421 126.805 12.7616 126.34 12.5834C125.875 12.4052 125.452 12.1331 125.096 11.7842C124.741 11.4352 124.461 11.0168 124.274 10.5551C124.087 10.0933 123.997 9.598 124.01 9.10001C124.001 8.59931 124.099 8.10251 124.298 7.64262C124.496 7.18272 124.789 6.77025 125.159 6.43259C125.529 6.09494 125.967 5.83983 126.443 5.68421C126.919 5.5286 127.422 5.47602 127.92 5.53001C131.82 5.59001 131.41 9.41001 131.41 9.41001L125.61 9.19001C125.583 9.51756 125.626 9.84711 125.735 10.157C125.844 10.4669 126.018 10.7502 126.245 10.9882C126.472 11.2262 126.746 11.4135 127.05 11.5379C127.354 11.6623 127.682 11.721 128.01 11.71C128.261 11.7305 128.514 11.6995 128.752 11.6189C128.991 11.5383 129.211 11.4098 129.398 11.2412C129.585 11.0727 129.736 10.8677 129.841 10.6388C129.947 10.4099 130.004 10.1619 130.01 9.91001L131.55 10.39C131.55 10.39 131.1 12.87 127.8 12.82ZM127.8 6.63999C127.282 6.60351 126.77 6.76987 126.373 7.10392C125.975 7.43797 125.723 7.91351 125.67 8.43H129.82C129.789 7.92857 129.562 7.45953 129.187 7.12449C128.813 6.78944 128.322 6.61536 127.82 6.63999H127.8Z" fill="black" />
                                          <path d="M135.71 10.61C135.32 10.61 134.78 10.75 134.71 11.28C134.64 11.81 135.02 11.99 135.71 12.06L138.08 12.26C140.24 12.45 140.78 13.43 140.7 14.36C140.59 15.6 139.45 16.25 136.86 16.02L135.24 15.88C132.94 15.68 132.54 14.71 132.6 14.07C132.68 13.17 133.7 12.73 134.89 12.84C134.89 12.84 132.98 12.62 133.08 11.44C133.17 10.5 134.52 10.44 135.08 10.44C134.587 10.2926 134.158 9.98348 133.863 9.56261C133.567 9.14174 133.422 8.63359 133.45 8.12001C133.56 6.88001 134.73 5.8 137.22 6.02C138.092 6.05502 138.937 6.33204 139.66 6.82002C139.16 5.70002 139.66 4.98 141.41 5.36L141.61 6.49001C140.3 6.14001 139.75 6.69001 140.11 7.27C140.281 7.47368 140.41 7.7095 140.489 7.96364C140.568 8.21777 140.596 8.48513 140.57 8.75002C140.46 10 139.29 11.06 136.84 10.84C136.458 10.7938 136.08 10.7169 135.71 10.61V10.61ZM135.8 13C135.8 13 134.35 12.88 134.26 14C134.19 14.78 134.78 15.11 136.65 15.28C138.29 15.42 139.04 15.21 139.1 14.43C139.1 13.9 138.86 13.55 137.9 13.43L135.8 13ZM136.94 9.88002C138.39 10 138.94 9.34 139.03 8.58C139.12 7.82 138.65 7.05 137.2 6.92C135.75 6.79 135.2 7.45 135.1 8.23C135 9.01 135.5 9.75002 136.94 9.88002V9.88002Z" fill="black" />
                                          <path d="M144.77 11.68C144.38 11.68 143.83 11.75 143.77 12.27C143.71 12.79 144.01 13 144.71 13.12L147.06 13.52C149.19 13.87 149.65 14.89 149.5 15.81C149.29 17.03 148.11 17.6 145.5 17.17L143.9 16.9C141.62 16.52 141.3 15.52 141.41 14.9C141.56 14.02 142.61 13.66 143.79 13.9C143.79 13.9 141.9 13.53 142.1 12.36C142.25 11.43 143.61 11.44 144.2 11.49C143.721 11.306 143.318 10.9664 143.055 10.5258C142.792 10.0851 142.685 9.56893 142.75 9.06C142.96 7.82 144.21 6.84001 146.68 7.26001C147.548 7.36491 148.369 7.71138 149.05 8.26001C148.64 7.10001 149.21 6.41999 150.91 6.92999L151.02 8.07999C149.74 7.62999 149.15 8.13998 149.47 8.72998C149.774 9.17433 149.896 9.71848 149.81 10.25C149.6 11.48 148.35 12.45 145.92 12.04C145.526 11.9562 145.141 11.8356 144.77 11.68V11.68ZM144.67 14.07C144.67 14.07 143.24 13.83 143.06 14.9C142.93 15.68 143.49 16.05 145.34 16.36C146.97 16.64 147.73 16.48 147.86 15.71C147.95 15.19 147.68 14.82 146.74 14.58L144.67 14.07ZM146.06 11.07C147.49 11.31 148.11 10.7 148.24 9.94C148.37 9.18 147.98 8.38999 146.55 8.14999C145.12 7.90999 144.48 8.51001 144.35 9.29001C144.22 10.07 144.63 10.8 146.06 11.04V11.07Z" fill="black" />
                                          <path d="M154.25 16.21C153.76 16.1215 153.294 15.9327 152.88 15.6553C152.467 15.3779 152.115 15.018 151.848 14.5982C151.58 14.1784 151.402 13.7078 151.325 13.2159C151.248 12.724 151.273 12.2215 151.4 11.74C151.504 11.2493 151.711 10.7863 152.008 10.382C152.305 9.97774 152.685 9.64137 153.122 9.39535C153.559 9.14933 154.044 8.99931 154.544 8.95528C155.043 8.91126 155.547 8.97422 156.02 9.14001C159.8 10.14 158.53 13.71 158.53 13.71L152.94 12.19C152.838 12.5026 152.803 12.8333 152.838 13.1604C152.873 13.4874 152.977 13.8032 153.143 14.0869C153.31 14.3706 153.535 14.6157 153.803 14.8059C154.071 14.9961 154.377 15.127 154.7 15.19C154.941 15.271 155.197 15.3015 155.451 15.2797C155.704 15.2579 155.951 15.1842 156.175 15.0633C156.399 14.9424 156.596 14.7767 156.753 14.5766C156.911 14.3765 157.025 14.1462 157.09 13.9L158.48 14.71C158.48 14.71 157.45 17.01 154.25 16.21ZM155.66 10.21C155.164 10.0721 154.634 10.125 154.175 10.3582C153.716 10.5914 153.361 10.9881 153.18 11.47L157.18 12.47C157.234 12.2208 157.237 11.9634 157.189 11.713C157.141 11.4627 157.043 11.2245 156.901 11.0127C156.76 10.8009 156.577 10.6198 156.363 10.4802C156.15 10.3405 155.911 10.2453 155.66 10.2V10.21Z" fill="black" />
                                          <path d="M166.22 15.17C166.59 14.06 166.1 13.3 164.91 12.91C164.669 12.7937 164.405 12.7298 164.137 12.7225C163.869 12.7151 163.603 12.7646 163.356 12.8675C163.108 12.9704 162.885 13.1246 162.702 13.3197C162.518 13.5149 162.378 13.7468 162.29 14L160.98 17.91L159.55 17.43L161.76 10.86L162.45 11.09L162.4 13.25C162.69 12.641 163.209 12.1712 163.844 11.9427C164.479 11.7142 165.178 11.7456 165.79 12.03C167.79 12.68 168.21 13.96 167.65 15.62L166.28 19.69L164.86 19.22L166.22 15.17Z" fill="black" />
                                          <path d="M177.13 13.84C177.592 14.0349 178.071 14.1888 178.56 14.3L174.73 23.22L173.95 22.89L174.17 21.19C173.786 21.6312 173.265 21.9317 172.691 22.044C172.117 22.1563 171.522 22.0739 171 21.81C170.56 21.6353 170.161 21.3702 169.829 21.0318C169.498 20.6935 169.241 20.2894 169.075 19.8456C168.909 19.4018 168.838 18.9282 168.866 18.4553C168.895 17.9824 169.022 17.5207 169.24 17.1C169.394 16.651 169.64 16.2391 169.963 15.8907C170.285 15.5423 170.677 15.2651 171.113 15.0768C171.549 14.8885 172.019 14.7933 172.494 14.7973C172.969 14.8013 173.437 14.9044 173.87 15.1C174.349 15.2794 174.766 15.5942 175.069 16.0061C175.373 16.418 175.55 16.9092 175.58 17.42L177.13 13.84ZM175.01 19.57C175.164 19.2854 175.256 18.9718 175.281 18.6494C175.307 18.327 175.264 18.0028 175.157 17.6977C175.05 17.3926 174.88 17.1133 174.658 16.8778C174.436 16.6422 174.168 16.4557 173.87 16.33C173.573 16.191 173.25 16.1173 172.923 16.1139C172.595 16.1105 172.271 16.1774 171.971 16.3101C171.671 16.4428 171.404 16.6382 171.186 16.8832C170.969 17.1281 170.806 17.4169 170.71 17.73C170.549 18.0155 170.451 18.3324 170.424 18.6591C170.396 18.9858 170.439 19.3146 170.55 19.6231C170.661 19.9316 170.838 20.2125 171.067 20.4467C171.297 20.6808 171.574 20.8627 171.88 20.98C172.176 21.1103 172.497 21.1769 172.82 21.1755C173.144 21.174 173.464 21.1046 173.759 20.9717C174.054 20.8387 174.318 20.6453 174.533 20.4038C174.749 20.1623 174.911 19.8783 175.01 19.57V19.57Z" fill="black" />
                                          <path d="M183.09 19.49C183.556 19.6835 183.977 19.9731 184.324 20.3397C184.671 20.7064 184.938 21.142 185.105 21.6183C185.273 22.0946 185.339 22.6008 185.299 23.1042C185.258 23.6075 185.112 24.0968 184.87 24.54C184.642 24.9916 184.32 25.39 183.928 25.7091C183.535 26.0283 183.079 26.261 182.59 26.3922C182.102 26.5234 181.591 26.55 181.091 26.4704C180.591 26.3907 180.114 26.2066 179.69 25.93C179.222 25.7366 178.8 25.4468 178.452 25.0793C178.104 24.7119 177.837 24.2752 177.669 23.7977C177.501 23.3202 177.436 22.8127 177.477 22.3082C177.519 21.8037 177.666 21.3137 177.91 20.87C178.139 20.4192 178.461 20.0218 178.854 19.7037C179.247 19.3855 179.703 19.1538 180.192 19.0236C180.681 18.8934 181.191 18.8677 181.691 18.948C182.19 19.0284 182.667 19.213 183.09 19.49V19.49ZM180.21 24.95C180.495 25.1067 180.81 25.2013 181.135 25.2277C181.459 25.254 181.786 25.2115 182.093 25.103C182.399 24.9944 182.68 24.8222 182.916 24.5976C183.151 24.3731 183.337 24.1012 183.46 23.8C183.64 23.5284 183.761 23.2216 183.813 22.9C183.866 22.5784 183.85 22.2492 183.766 21.9344C183.681 21.6195 183.531 21.3261 183.325 21.0737C183.119 20.8212 182.862 20.6154 182.57 20.47C182.285 20.31 181.97 20.2127 181.645 20.1847C181.319 20.1566 180.992 20.1985 180.684 20.3074C180.376 20.4163 180.095 20.5898 179.86 20.8162C179.625 21.0425 179.441 21.3166 179.32 21.62C179.138 21.8911 179.016 22.1979 178.963 22.5199C178.909 22.8418 178.925 23.1716 179.009 23.4869C179.094 23.8023 179.244 24.096 179.451 24.3483C179.659 24.6006 179.917 24.8058 180.21 24.95Z" fill="black" />
                                          <path d="M199.04 29.7L193.45 37.7L192.22 36.84L194.56 33.5C194.109 33.7445 193.597 33.8528 193.086 33.812C192.575 33.7712 192.086 33.5829 191.68 33.27C191.283 33.0062 190.946 32.6623 190.691 32.2604C190.435 31.8584 190.267 31.4074 190.196 30.9363C190.125 30.4653 190.154 29.9847 190.28 29.5254C190.407 29.0662 190.628 28.6385 190.93 28.27C191.174 27.863 191.5 27.5113 191.887 27.2374C192.274 26.9635 192.714 26.7734 193.179 26.6793C193.644 26.5852 194.124 26.5892 194.587 26.6909C195.05 26.7926 195.487 26.9898 195.87 27.27C196.363 27.5756 196.741 28.0354 196.945 28.5779C197.149 29.1205 197.169 29.7153 197 30.27L198.34 29.19L199.04 29.7ZM192.24 29.16C192.023 29.4067 191.862 29.6971 191.767 30.0115C191.673 30.3259 191.647 30.657 191.692 30.9824C191.736 31.3077 191.85 31.6196 192.026 31.897C192.202 32.1744 192.435 32.4107 192.71 32.59C192.974 32.7795 193.274 32.9113 193.592 32.9768C193.91 33.0424 194.238 33.0402 194.555 32.9703C194.872 32.9005 195.171 32.7646 195.432 32.5716C195.693 32.3786 195.91 32.1327 196.07 31.85C196.279 31.6038 196.435 31.3164 196.526 31.0063C196.618 30.6963 196.643 30.3705 196.601 30.05C196.558 29.7295 196.449 29.4215 196.28 29.1459C196.111 28.8703 195.886 28.6332 195.62 28.45C195.359 28.2526 195.058 28.1136 194.739 28.0421C194.42 27.9707 194.089 27.9685 193.768 28.0358C193.448 28.1031 193.146 28.2382 192.882 28.4321C192.619 28.6261 192.4 28.8743 192.24 29.16Z" fill="black" />
                                          <path d="M199.55 35.54C198.8 36.43 198.99 37.32 199.96 38.12C200.145 38.3142 200.368 38.4673 200.616 38.5695C200.864 38.6716 201.13 38.7205 201.398 38.713C201.666 38.7054 201.929 38.6416 202.17 38.5257C202.412 38.4097 202.627 38.2443 202.8 38.04L205.43 34.86L206.59 35.86L202.16 41.2L201.6 40.74L202.43 38.74C201.937 39.2016 201.284 39.4517 200.609 39.4368C199.934 39.4218 199.292 39.143 198.82 38.66C197.24 37.34 197.26 35.98 198.38 34.66L201.12 31.35L202.27 32.35L199.55 35.54Z" fill="black" />
                                          <path d="M206.08 45.09C205.7 44.7682 205.39 44.371 205.17 43.9236C204.951 43.4762 204.827 42.9882 204.805 42.4904C204.783 41.9925 204.865 41.4955 205.045 41.0308C205.225 40.5661 205.499 40.1436 205.85 39.79C206.186 39.4179 206.598 39.1217 207.058 38.9211C207.517 38.7206 208.014 38.6202 208.516 38.6269C209.017 38.6335 209.512 38.7469 209.966 38.9595C210.42 39.1722 210.824 39.4791 211.15 39.86C213.95 42.57 211.04 45.08 211.04 45.08L206.96 40.95C206.713 41.1668 206.516 41.4337 206.381 41.7329C206.245 42.032 206.175 42.3566 206.175 42.685C206.175 43.0134 206.245 43.338 206.381 43.6371C206.516 43.9363 206.713 44.2032 206.96 44.42C207.126 44.6119 207.33 44.7674 207.559 44.8767C207.788 44.986 208.037 45.0468 208.29 45.0552C208.544 45.0637 208.797 45.0196 209.032 44.9258C209.268 44.832 209.482 44.6904 209.66 44.51L210.46 45.91C210.46 45.91 208.45 47.37 206.08 45.09ZM210.31 40.6C209.957 40.2187 209.469 39.9899 208.95 39.9619C208.432 39.9339 207.922 40.1089 207.53 40.45L210.53 43.29C210.693 43.0994 210.817 42.8784 210.895 42.6397C210.972 42.4009 211.001 42.1492 210.981 41.8991C210.96 41.649 210.89 41.4055 210.775 41.1825C210.66 40.9595 210.502 40.7615 210.31 40.6V40.6Z" fill="black" />
                                          <path d="M216.32 46.85C215.64 46.09 215.07 46.03 214.7 46.37C214.33 46.71 214.46 47.18 214.96 48L215.87 49.47C216.7 50.81 216.53 51.73 215.87 52.34C214.87 53.23 213.33 52.76 212.19 51.48C209.77 48.76 211.39 46.84 211.39 46.84L212.75 47.69C212.75 47.69 211.24 48.74 213.03 50.75C213.65 51.45 214.32 51.57 214.71 51.23C215.1 50.89 215.08 50.42 214.5 49.47L213.69 48.15C212.87 46.81 213.06 45.87 213.69 45.31C214.69 44.44 216.1 44.91 217.19 46.13C219.3 48.5 217.88 50.04 217.88 50.04L216.51 49.18C216.51 49.18 217.65 48.37 216.32 46.85Z" fill="black" />
                                          <path d="M220.19 49.86L221.19 51.13L222.43 50.13L224.11 50.65L222.11 52.24L223.87 54.48L223.13 55.06L221.37 52.82L218.63 54.97C217.77 55.64 217.63 56.26 218.14 56.89C218.336 57.1603 218.608 57.3658 218.922 57.4799C219.235 57.594 219.576 57.6114 219.9 57.53L219.21 58.45C218.79 58.4446 218.378 58.3404 218.006 58.1458C217.634 57.9512 217.313 57.6717 217.07 57.33C216.836 57.0886 216.66 56.7965 216.557 56.4764C216.454 56.1563 216.426 55.8167 216.476 55.4841C216.525 55.1514 216.65 54.8346 216.842 54.5581C217.033 54.2817 217.286 54.0531 217.58 53.89L220.45 51.63L219.45 50.42L220.19 49.86Z" fill="black" />
                                          <path d="M227.16 58.93C227.482 59.32 227.718 59.7735 227.853 60.2609C227.988 60.7482 228.018 61.2585 227.943 61.7585C227.867 62.2586 227.687 62.7371 227.415 63.1629C227.142 63.5887 226.783 63.9523 226.36 64.23C225.946 64.5219 225.475 64.7232 224.977 64.8206C224.48 64.9179 223.968 64.9092 223.474 64.795C222.981 64.6808 222.517 64.4636 222.113 64.1577C221.709 63.8519 221.374 63.4642 221.13 63.02C220.808 62.6285 220.573 62.1737 220.439 61.6851C220.304 61.1966 220.274 60.6852 220.351 60.1844C220.427 59.6835 220.608 59.2044 220.882 58.778C221.156 58.3517 221.516 57.9878 221.94 57.71C222.354 57.4201 222.825 57.2209 223.322 57.1252C223.819 57.0295 224.33 57.0396 224.822 57.1547C225.315 57.2698 225.777 57.4874 226.18 57.7933C226.583 58.0992 226.917 58.4865 227.16 58.93V58.93ZM222.05 62.37C222.227 62.6436 222.461 62.8764 222.735 63.0529C223.009 63.2295 223.317 63.3457 223.64 63.3939C223.962 63.4422 224.291 63.4213 224.605 63.3327C224.919 63.2441 225.21 63.0898 225.46 62.88C225.746 62.7249 225.995 62.5111 226.192 62.2526C226.389 61.9941 226.529 61.6968 226.603 61.3802C226.676 61.0637 226.682 60.7352 226.62 60.4163C226.557 60.0973 226.428 59.7953 226.24 59.53C226.064 59.2559 225.832 59.0223 225.56 58.8449C225.287 58.6675 224.979 58.5503 224.657 58.5011C224.336 58.4519 224.007 58.4718 223.694 58.5595C223.38 58.6473 223.089 58.8009 222.84 59.01C222.551 59.1628 222.298 59.3758 222.098 59.6346C221.898 59.8934 221.756 60.192 221.681 60.5104C221.606 60.8287 221.6 61.1594 221.664 61.4802C221.728 61.8009 221.859 62.1043 222.05 62.37V62.37Z" fill="black" />
                                          <path d="M225.45 68.93C224.644 69.4004 223.692 69.5537 222.78 69.36L222.49 68.25C223.207 68.606 224.032 68.6774 224.8 68.45L224.35 67.69L225.9 66.76L226.74 68.15L225.45 68.93Z" fill="black" />
                                          <path d="M235.36 75.48C234.93 74.56 234.36 74.34 233.94 74.56C233.52 74.78 233.48 75.27 233.74 76.2L234.19 77.87C234.61 79.39 234.19 80.22 233.36 80.61C232.15 81.18 230.81 80.3 230.07 78.75C228.52 75.46 230.62 74.07 230.62 74.07L231.69 75.28C231.69 75.28 229.94 75.85 231.09 78.28C231.49 79.12 232.09 79.43 232.56 79.21C233.03 78.99 233.15 78.55 232.86 77.47L232.46 75.97C232.04 74.45 232.46 73.6 233.25 73.25C234.43 72.68 235.68 73.53 236.38 75.02C237.73 77.89 235.93 78.96 235.93 78.96L234.86 77.75C234.86 77.75 236.22 77.29 235.36 75.48Z" fill="black" />
                                          <path d="M238.51 80.22L239.08 81.61L232.67 84.25L232.09 82.86L238.51 80.22ZM241.07 79.22L241.64 80.6L240.53 81.06L239.96 79.67L241.07 79.22Z" fill="black" />
                                          <path d="M236.38 94.95L235.91 93.38L241.63 88.29L242.13 89.95L237.43 93.78L243.43 94.42L243.92 96.05L236.38 94.95Z" fill="black" />
                                          <path d="M238.15 102.55C238.01 102.072 237.97 101.569 238.034 101.075C238.097 100.581 238.262 100.105 238.519 99.6772C238.775 99.2498 239.117 98.8801 239.524 98.5914C239.93 98.3028 240.392 98.1014 240.88 98C241.367 97.8755 241.876 97.8563 242.371 97.9437C242.867 98.0312 243.338 98.2232 243.753 98.5071C244.169 98.791 244.519 99.1602 244.78 99.5901C245.042 100.02 245.209 100.501 245.27 101C246.11 104.81 242.27 105.29 242.27 105.29L241.15 99.6C240.825 99.6475 240.514 99.7637 240.237 99.9409C239.961 100.118 239.726 100.352 239.547 100.627C239.368 100.903 239.25 101.213 239.2 101.538C239.151 101.862 239.171 102.194 239.26 102.51C239.293 102.762 239.378 103.004 239.509 103.222C239.641 103.44 239.816 103.628 240.023 103.774C240.231 103.921 240.467 104.023 240.716 104.074C240.965 104.124 241.221 104.123 241.47 104.07L241.36 105.68C241.36 105.68 238.86 105.77 238.15 102.55ZM244.15 101.14C244.066 100.628 243.787 100.169 243.37 99.8592C242.954 99.5495 242.434 99.4135 241.92 99.48L242.87 103.48C243.348 103.336 243.751 103.011 243.992 102.573C244.233 102.136 244.294 101.621 244.16 101.14H244.15Z" fill="black" />
                                          <path d="M249.66 112.06C249.734 112.558 249.848 113.05 250 113.53L240.37 114.85L240.26 114.01L241.83 113.31C241.253 113.211 240.727 112.922 240.333 112.489C239.94 112.056 239.703 111.503 239.66 110.92C239.584 110.452 239.606 109.974 239.725 109.516C239.845 109.057 240.059 108.629 240.354 108.258C240.648 107.887 241.017 107.581 241.437 107.361C241.856 107.141 242.317 107.011 242.79 106.98C243.255 106.882 243.734 106.883 244.199 106.981C244.663 107.079 245.102 107.274 245.487 107.551C245.872 107.829 246.195 108.184 246.435 108.594C246.675 109.003 246.826 109.458 246.88 109.93C246.971 110.433 246.915 110.952 246.718 111.424C246.521 111.896 246.192 112.301 245.77 112.59L249.66 112.06ZM243.66 113.2C243.984 113.187 244.302 113.105 244.593 112.961C244.884 112.817 245.142 112.614 245.349 112.364C245.556 112.114 245.709 111.824 245.797 111.511C245.885 111.199 245.907 110.871 245.86 110.55C245.828 110.222 245.726 109.904 245.56 109.619C245.394 109.334 245.169 109.088 244.899 108.898C244.63 108.708 244.322 108.579 243.998 108.519C243.673 108.459 243.34 108.469 243.02 108.55C242.693 108.559 242.372 108.639 242.079 108.784C241.786 108.928 241.527 109.134 241.32 109.387C241.113 109.64 240.964 109.935 240.881 110.251C240.798 110.568 240.784 110.898 240.84 111.22C240.878 111.541 240.984 111.85 241.149 112.128C241.314 112.406 241.536 112.646 241.8 112.832C242.064 113.019 242.364 113.148 242.681 113.211C242.998 113.275 243.325 113.271 243.64 113.2H243.66Z" fill="black" />
                                          <path d="M240.61 120.42C240.546 119.925 240.585 119.422 240.726 118.943C240.866 118.464 241.105 118.019 241.427 117.638C241.748 117.256 242.146 116.945 242.594 116.725C243.042 116.505 243.531 116.381 244.03 116.36C244.529 116.311 245.033 116.37 245.508 116.532C245.983 116.694 246.418 116.955 246.784 117.299C247.149 117.643 247.437 118.061 247.628 118.525C247.819 118.989 247.908 119.489 247.89 119.99C248.12 123.88 244.28 123.76 244.28 123.76L244.06 117.96C243.731 117.957 243.406 118.023 243.105 118.155C242.803 118.287 242.534 118.481 242.314 118.725C242.093 118.969 241.927 119.257 241.827 119.57C241.726 119.883 241.693 120.213 241.73 120.54C241.724 120.794 241.771 121.047 241.867 121.282C241.963 121.517 242.107 121.73 242.289 121.907C242.471 122.085 242.687 122.223 242.925 122.313C243.163 122.402 243.416 122.442 243.67 122.43L243.31 124C243.31 124 240.8 123.71 240.61 120.42ZM246.77 119.97C246.77 119.448 246.566 118.947 246.202 118.573C245.838 118.2 245.342 117.983 244.82 117.97L245.13 122.11C245.379 122.078 245.618 121.997 245.835 121.872C246.053 121.747 246.243 121.58 246.395 121.381C246.548 121.182 246.659 120.955 246.724 120.713C246.788 120.471 246.804 120.218 246.77 119.97V119.97Z" fill="black" />
                                          <path d="M242.81 138.51C242.81 138.51 240.36 137.9 240.57 134.63C240.567 134.131 240.668 133.638 240.865 133.18C241.063 132.722 241.353 132.31 241.717 131.97C242.082 131.63 242.513 131.369 242.983 131.204C243.453 131.039 243.953 130.973 244.45 131.01C244.944 131.024 245.431 131.141 245.877 131.355C246.323 131.57 246.718 131.875 247.038 132.253C247.358 132.63 247.595 133.071 247.733 133.546C247.871 134.021 247.908 134.52 247.84 135.01C247.66 137.84 245.75 138.42 244.44 138.4L244.08 136.83C245.31 137.01 246.63 136.58 246.73 134.94C246.82 133.52 245.82 132.69 244.35 132.6C244.021 132.548 243.684 132.568 243.363 132.658C243.042 132.748 242.745 132.906 242.49 133.122C242.236 133.337 242.031 133.605 241.89 133.906C241.748 134.208 241.673 134.537 241.67 134.87C241.634 135.121 241.649 135.377 241.716 135.621C241.782 135.866 241.898 136.094 242.057 136.292C242.215 136.49 242.413 136.653 242.637 136.771C242.861 136.89 243.107 136.961 243.36 136.98L242.81 138.51Z" fill="black" />
                                          <path d="M245.24 142.29C245.807 142.509 246.275 142.927 246.555 143.467C246.835 144.007 246.908 144.63 246.76 145.22C246.47 147.22 245.3 147.95 243.57 147.7L239.31 147.09L239.52 145.61L243.75 146.22C244.91 146.38 245.56 145.75 245.75 144.52C245.821 144.261 245.836 143.99 245.795 143.725C245.753 143.46 245.656 143.207 245.509 142.982C245.363 142.757 245.171 142.566 244.945 142.421C244.719 142.276 244.465 142.18 244.2 142.14L240.12 141.55L240.33 140.06L249.81 141.42C249.732 141.919 249.705 142.425 249.73 142.93L245.24 142.29Z" fill="black" />
                                          <path d="M237.99 152.5C238.067 152.008 238.246 151.537 238.514 151.117C238.782 150.696 239.135 150.337 239.549 150.059C239.963 149.782 240.431 149.594 240.921 149.506C241.412 149.419 241.915 149.433 242.4 149.55C242.892 149.642 243.359 149.838 243.77 150.125C244.18 150.412 244.525 150.783 244.78 151.214C245.036 151.644 245.196 152.125 245.252 152.622C245.307 153.12 245.255 153.624 245.1 154.1C244.24 157.91 240.59 156.72 240.59 156.72L241.99 151.09C241.672 150.988 241.335 150.956 241.003 150.995C240.672 151.035 240.352 151.146 240.067 151.321C239.783 151.496 239.539 151.73 239.353 152.008C239.167 152.285 239.043 152.6 238.99 152.93C238.913 153.172 238.887 153.428 238.914 153.681C238.94 153.933 239.019 154.178 239.145 154.399C239.271 154.619 239.441 154.812 239.645 154.963C239.849 155.115 240.082 155.223 240.33 155.28L239.55 156.69C239.55 156.69 237.27 155.72 237.99 152.5ZM243.99 153.77C244.116 153.271 244.051 152.742 243.806 152.288C243.562 151.835 243.156 151.489 242.67 151.32L241.82 155.39C242.073 155.434 242.332 155.427 242.581 155.369C242.831 155.31 243.067 155.202 243.274 155.051C243.481 154.9 243.656 154.709 243.787 154.489C243.919 154.268 244.005 154.024 244.04 153.77H243.99Z" fill="black" />
                                          <path d="M237.45 169.64C238.55 170.04 239.32 169.56 239.74 168.38C239.862 168.143 239.933 167.882 239.948 167.616C239.963 167.349 239.922 167.083 239.828 166.833C239.734 166.583 239.588 166.356 239.401 166.166C239.213 165.976 238.988 165.828 238.74 165.73L234.86 164.33L235.37 162.92L241.89 165.28L241.65 165.96L239.49 165.86C240.091 166.166 240.547 166.697 240.76 167.337C240.973 167.976 240.927 168.674 240.63 169.28C239.93 171.22 238.63 171.65 237 171.06L233 169.59L233.51 168.18L237.45 169.64Z" fill="black" />
                                          <path d="M237.13 177.72C236.963 178.197 236.697 178.634 236.35 179.002C236.003 179.371 235.583 179.662 235.116 179.857C234.649 180.052 234.147 180.147 233.641 180.135C233.135 180.124 232.637 180.006 232.18 179.79C231.716 179.586 231.301 179.287 230.96 178.912C230.62 178.538 230.362 178.095 230.203 177.614C230.045 177.133 229.99 176.624 230.041 176.121C230.092 175.617 230.248 175.129 230.5 174.69C230.666 174.206 230.934 173.763 231.284 173.39C231.635 173.017 232.06 172.723 232.533 172.528C233.006 172.332 233.515 172.239 234.027 172.255C234.538 172.271 235.04 172.395 235.5 172.62C235.959 172.828 236.37 173.131 236.705 173.508C237.041 173.885 237.293 174.328 237.447 174.808C237.6 175.289 237.652 175.796 237.597 176.298C237.542 176.799 237.383 177.284 237.13 177.72V177.72ZM231.51 175.15C231.361 175.446 231.278 175.77 231.266 176.101C231.254 176.432 231.314 176.762 231.441 177.067C231.568 177.373 231.76 177.648 232.003 177.873C232.246 178.097 232.535 178.267 232.85 178.37C233.131 178.534 233.443 178.637 233.766 178.671C234.09 178.706 234.417 178.671 234.726 178.57C235.035 178.469 235.319 178.303 235.559 178.085C235.799 177.866 235.991 177.598 236.12 177.3C236.264 177.008 236.344 176.688 236.354 176.362C236.365 176.036 236.305 175.712 236.18 175.411C236.055 175.11 235.868 174.839 235.63 174.616C235.392 174.394 235.109 174.225 234.8 174.12C234.521 173.951 234.209 173.844 233.886 173.805C233.562 173.766 233.234 173.796 232.923 173.893C232.612 173.991 232.325 174.153 232.081 174.37C231.838 174.586 231.643 174.852 231.51 175.15Z" fill="black" />
                                          <path d="M229.24 186.83C230.24 187.4 231.1 187.05 231.71 185.96C231.868 185.743 231.977 185.495 232.033 185.232C232.088 184.97 232.087 184.698 232.031 184.436C231.974 184.174 231.863 183.926 231.704 183.71C231.546 183.494 231.343 183.313 231.11 183.18L227.51 181.18L228.24 179.87L234.3 183.25L233.94 183.88L231.83 183.43C232.375 183.828 232.741 184.425 232.849 185.091C232.958 185.757 232.8 186.439 232.41 186.99C231.41 188.79 230.06 188.99 228.53 188.16L224.78 186.07L225.51 184.76L229.24 186.83Z" fill="black" />
                                          <path d="M224.05 196.56C224.446 197.021 224.66 197.612 224.65 198.219C224.641 198.827 224.41 199.411 224 199.86C222.78 201.52 221.42 201.58 220 200.54L216.54 197.98L217.43 196.78L220.87 199.32C221.81 200.01 222.68 199.77 223.42 198.76C223.606 198.566 223.749 198.335 223.839 198.081C223.929 197.828 223.963 197.559 223.941 197.291C223.918 197.023 223.838 196.763 223.707 196.528C223.576 196.294 223.396 196.09 223.18 195.93L219.87 193.48L220.76 192.27L228.46 197.96C228.155 198.361 227.894 198.794 227.68 199.25L224.05 196.56Z" fill="black" />
                                          <path d="M211.72 205.12C211.607 205.274 211.537 205.455 211.518 205.645C211.499 205.835 211.53 206.027 211.61 206.2L210.61 205.7C210.58 205.401 210.617 205.1 210.719 204.818C210.821 204.536 210.986 204.28 211.2 204.07C211.325 203.896 211.487 203.751 211.675 203.648C211.863 203.545 212.072 203.486 212.286 203.474C212.5 203.463 212.714 203.499 212.912 203.582C213.11 203.664 213.287 203.79 213.43 203.95L214.33 204.72C214.33 204.72 212.87 202.14 214.22 200.57C214.344 200.396 214.502 200.251 214.685 200.141C214.868 200.032 215.072 199.962 215.284 199.936C215.495 199.91 215.71 199.928 215.914 199.989C216.118 200.049 216.308 200.152 216.47 200.29C217.35 201.05 217.9 202.88 215.79 205.98C215.929 206.123 216.097 206.233 216.284 206.302C216.47 206.372 216.67 206.399 216.868 206.382C217.066 206.365 217.258 206.303 217.43 206.203C217.601 206.102 217.748 205.965 217.86 205.8C218.153 205.478 218.332 205.07 218.372 204.637C218.412 204.204 218.309 203.77 218.08 203.4L219.44 202.52C219.762 203.176 219.866 203.917 219.739 204.636C219.611 205.355 219.258 206.015 218.73 206.52C217.38 208.09 215.87 208.13 214.82 207.17L212.66 205.17C212.3 204.89 211.98 204.82 211.72 205.12ZM214.65 201.73C213.78 202.73 214.65 205.09 215.1 205.42L215.43 205.7C216.71 203.13 216.09 202.1 215.63 201.7C215.569 201.629 215.492 201.572 215.406 201.534C215.32 201.496 215.227 201.478 215.133 201.481C215.039 201.484 214.947 201.508 214.863 201.551C214.78 201.594 214.707 201.655 214.65 201.73V201.73Z" fill="black" />
                                          <path d="M214.45 210.9L213.45 211.98L208.45 207.17L209.45 206.09L214.45 210.9ZM216.45 212.83L215.45 213.91L214.59 213.07L215.59 211.99L216.45 212.83Z" fill="black" />
                                          <path d="M198.74 217.29C198.598 217.426 198.495 217.599 198.444 217.79C198.393 217.98 198.395 218.181 198.45 218.37L197.5 217.71C197.515 217.408 197.599 217.114 197.744 216.849C197.89 216.584 198.093 216.355 198.34 216.18C198.491 216.027 198.675 215.911 198.878 215.838C199.08 215.766 199.296 215.74 199.51 215.762C199.724 215.784 199.93 215.854 200.114 215.966C200.297 216.078 200.453 216.23 200.57 216.41L201.33 217.31C201.33 217.31 200.28 214.53 201.86 213.18C202.01 213.028 202.19 212.908 202.388 212.829C202.586 212.75 202.799 212.713 203.013 212.72C203.226 212.728 203.436 212.78 203.628 212.873C203.82 212.966 203.991 213.097 204.13 213.26C204.88 214.15 205.13 216.04 202.58 218.78C202.695 218.944 202.845 219.079 203.019 219.177C203.193 219.275 203.387 219.333 203.587 219.347C203.786 219.36 203.986 219.329 204.172 219.255C204.357 219.181 204.524 219.067 204.66 218.92C204.998 218.646 205.238 218.269 205.342 217.847C205.447 217.425 205.411 216.98 205.24 216.58L206.72 215.92C206.933 216.612 206.922 217.355 206.689 218.041C206.455 218.727 206.011 219.321 205.42 219.74C203.83 221.08 202.34 220.89 201.42 219.74L199.59 217.47C199.35 217.15 199.04 217.03 198.74 217.29ZM202.16 214.39C201.16 215.24 201.67 217.72 202.03 218.1L202.32 218.44C203.98 216.09 203.53 214.98 203.13 214.51C203.082 214.429 203.017 214.36 202.938 214.308C202.86 214.256 202.771 214.222 202.678 214.208C202.585 214.195 202.491 214.202 202.401 214.23C202.311 214.258 202.229 214.306 202.16 214.37V214.39Z" fill="black" />
                                          <path d="M194.45 224.66C195.13 225.6 196.04 225.66 197.05 224.91C197.284 224.779 197.488 224.599 197.649 224.384C197.81 224.169 197.923 223.922 197.983 223.66C198.042 223.398 198.046 223.126 197.994 222.863C197.942 222.599 197.835 222.35 197.68 222.13L195.26 218.79L196.47 217.91L200.55 223.53L199.96 223.96L198.23 222.66C198.556 223.253 198.633 223.951 198.446 224.601C198.259 225.252 197.821 225.801 197.23 226.13C195.56 227.34 194.23 226.98 193.23 225.56L190.7 222.08L191.92 221.2L194.45 224.66Z" fill="black" />
                                          <path d="M184.02 228.96C184.02 228.96 183.11 226.61 185.91 224.89C186.317 224.602 186.78 224.402 187.268 224.302C187.757 224.202 188.261 224.204 188.748 224.308C189.236 224.412 189.696 224.617 190.101 224.908C190.506 225.199 190.846 225.571 191.1 226C191.374 226.413 191.558 226.879 191.639 227.368C191.721 227.857 191.698 228.358 191.572 228.838C191.447 229.317 191.221 229.765 190.911 230.151C190.6 230.538 190.211 230.854 189.77 231.08C187.34 232.56 185.77 231.32 185.03 230.24L186.12 229.04C186.68 230.15 187.79 230.98 189.19 230.13C190.4 229.39 190.5 228.13 189.74 226.83C189.592 226.53 189.382 226.266 189.123 226.054C188.863 225.843 188.562 225.69 188.238 225.606C187.915 225.521 187.577 225.507 187.248 225.565C186.918 225.623 186.605 225.751 186.33 225.94C186.105 226.056 185.905 226.217 185.745 226.412C185.584 226.608 185.464 226.835 185.394 227.078C185.324 227.322 185.304 227.577 185.336 227.828C185.368 228.08 185.451 228.322 185.58 228.54L184.02 228.96Z" fill="black" />
                                          <path d="M181.37 235.76C180.941 236.029 180.461 236.205 179.96 236.276C179.459 236.347 178.949 236.312 178.463 236.174C177.976 236.035 177.524 235.796 177.136 235.471C176.748 235.147 176.433 234.744 176.21 234.29C175.979 233.844 175.843 233.354 175.813 232.853C175.782 232.351 175.857 231.849 176.032 231.378C176.207 230.907 176.479 230.478 176.83 230.118C177.181 229.759 177.603 229.476 178.07 229.29C178.499 229.019 178.98 228.842 179.482 228.77C179.984 228.698 180.495 228.732 180.983 228.871C181.471 229.01 181.924 229.25 182.313 229.575C182.702 229.901 183.018 230.304 183.24 230.76C183.468 231.207 183.6 231.697 183.629 232.198C183.657 232.7 183.581 233.201 183.405 233.671C183.229 234.142 182.957 234.57 182.607 234.93C182.257 235.289 181.835 235.572 181.37 235.76ZM178.58 230.25C178.287 230.392 178.027 230.595 177.818 230.845C177.608 231.094 177.454 231.386 177.365 231.699C177.277 232.013 177.255 232.342 177.302 232.664C177.35 232.986 177.465 233.295 177.64 233.57C177.756 233.874 177.936 234.149 178.167 234.378C178.397 234.607 178.674 234.784 178.979 234.897C179.284 235.011 179.609 235.058 179.933 235.036C180.257 235.014 180.573 234.923 180.86 234.77C181.156 234.632 181.419 234.433 181.631 234.184C181.843 233.936 182 233.646 182.09 233.332C182.18 233.018 182.202 232.688 182.153 232.365C182.105 232.043 181.988 231.734 181.81 231.46C181.695 231.154 181.516 230.877 181.285 230.646C181.054 230.415 180.776 230.236 180.47 230.121C180.164 230.007 179.837 229.959 179.511 229.981C179.185 230.003 178.868 230.095 178.58 230.25Z" fill="black" />
                                          <path d="M171.67 232.45L173.05 231.87L175.76 238.26L175.09 238.54L173.7 236.89C173.7 236.89 174.35 239.04 172.59 239.79C171.53 240.24 170.89 239.65 170.53 238.79C170.33 238.331 170.215 237.84 170.19 237.34L171.45 236.37C171.504 236.908 171.631 237.437 171.83 237.94C172.07 238.5 172.37 238.71 172.75 238.55C173.64 238.17 173.44 236.64 173.28 236.27L171.67 232.45Z" fill="black" />
                                          <path d="M161.45 237.31C161.275 237.386 161.124 237.508 161.013 237.663C160.902 237.818 160.835 238 160.82 238.19L160.17 237.24C160.292 236.963 160.475 236.718 160.705 236.522C160.936 236.327 161.207 236.186 161.5 236.11C161.695 236.022 161.908 235.978 162.122 235.982C162.336 235.986 162.547 236.038 162.738 236.134C162.93 236.23 163.098 236.367 163.229 236.536C163.361 236.705 163.454 236.901 163.5 237.11L163.89 238.23C163.89 238.23 163.89 235.23 165.89 234.58C166.083 234.491 166.293 234.444 166.505 234.441C166.718 234.438 166.928 234.479 167.124 234.562C167.32 234.644 167.496 234.767 167.642 234.922C167.787 235.076 167.899 235.26 167.97 235.46C168.35 236.56 167.92 238.46 164.54 240.05C164.589 240.243 164.679 240.422 164.806 240.575C164.932 240.729 165.091 240.852 165.271 240.936C165.451 241.02 165.648 241.063 165.846 241.062C166.045 241.061 166.241 241.016 166.42 240.93C166.846 240.807 167.217 240.541 167.47 240.177C167.724 239.813 167.843 239.372 167.81 238.93L169.43 238.85C169.378 239.573 169.098 240.262 168.631 240.817C168.164 241.372 167.534 241.765 166.83 241.94C164.88 242.62 163.55 241.94 163.12 240.54L162.23 237.77C162.09 237.37 161.84 237.17 161.45 237.31ZM165.69 235.83C164.44 236.26 164.04 238.76 164.23 239.25L164.38 239.66C166.78 238.07 166.76 236.87 166.55 236.29C166.533 236.199 166.496 236.112 166.442 236.037C166.387 235.962 166.317 235.9 166.235 235.856C166.154 235.812 166.063 235.787 165.971 235.782C165.878 235.778 165.786 235.794 165.7 235.83H165.69Z" fill="black" />
                                          <path d="M155.51 245.16L154.05 245.51L152.45 238.77L153.91 238.42L155.51 245.16ZM156.16 247.86L154.7 248.21L154.45 247.04L155.91 246.69L156.16 247.86Z" fill="black" />
                                          <path d="M140.79 245.23C140.792 245.47 140.851 245.706 140.961 245.918C141.072 246.131 141.231 246.315 141.426 246.454C141.621 246.594 141.846 246.685 142.083 246.721C142.32 246.757 142.563 246.736 142.79 246.66C143.048 246.638 143.299 246.564 143.526 246.441C143.754 246.318 143.954 246.15 144.114 245.946C144.273 245.742 144.389 245.508 144.454 245.257C144.518 245.007 144.531 244.746 144.49 244.49L143.84 240.42L145.32 240.19L146 244.49C146.016 244.717 146.083 244.938 146.196 245.136C146.308 245.333 146.464 245.503 146.651 245.633C146.838 245.763 147.052 245.848 147.277 245.884C147.502 245.92 147.732 245.905 147.95 245.84C148.207 245.82 148.458 245.746 148.686 245.625C148.913 245.503 149.113 245.335 149.273 245.132C149.433 244.929 149.549 244.696 149.614 244.446C149.678 244.196 149.691 243.935 149.65 243.68L149 239.6L150.45 239.37L151.54 246.22L150.82 246.33L149.86 244.37C149.859 245.035 149.612 245.676 149.168 246.171C148.723 246.666 148.111 246.979 147.45 247.05C147.17 247.14 146.874 247.171 146.581 247.142C146.289 247.114 146.005 247.025 145.747 246.883C145.49 246.741 145.264 246.547 145.084 246.315C144.904 246.082 144.773 245.815 144.7 245.53C144.627 246.137 144.348 246.701 143.909 247.126C143.47 247.552 142.899 247.815 142.29 247.87C140.38 248.17 139.62 247.21 139.34 245.49L138.67 241.24L140.15 241L140.79 245.23Z" fill="black" />
                                          <path d="M136.14 248.37L135.57 246.74C135.429 247.31 135.102 247.817 134.64 248.179C134.177 248.542 133.607 248.739 133.02 248.74C132.547 248.779 132.071 248.719 131.623 248.563C131.175 248.407 130.764 248.159 130.418 247.835C130.071 247.511 129.796 247.118 129.611 246.682C129.425 246.245 129.333 245.774 129.34 245.3C129.266 244.823 129.294 244.336 129.423 243.871C129.551 243.405 129.777 242.973 130.085 242.601C130.393 242.23 130.777 241.928 131.21 241.716C131.644 241.503 132.118 241.386 132.6 241.37C133.112 241.312 133.63 241.408 134.087 241.644C134.545 241.881 134.922 242.249 135.17 242.7L134.94 238.62L136.44 238.53L136.99 248.25L136.14 248.37ZM135.61 244.93C135.622 244.605 135.565 244.282 135.445 243.98C135.324 243.678 135.141 243.405 134.909 243.178C134.676 242.951 134.399 242.775 134.094 242.662C133.789 242.549 133.464 242.5 133.14 242.52C132.812 242.528 132.49 242.607 132.195 242.751C131.9 242.894 131.639 243.1 131.431 243.353C131.222 243.606 131.07 243.901 130.986 244.218C130.901 244.535 130.885 244.867 130.94 245.19C130.93 245.515 130.989 245.838 131.114 246.138C131.239 246.438 131.426 246.708 131.664 246.929C131.902 247.151 132.184 247.319 132.492 247.423C132.8 247.526 133.127 247.563 133.45 247.53C133.771 247.511 134.084 247.426 134.371 247.28C134.657 247.133 134.909 246.929 135.112 246.68C135.316 246.431 135.465 246.142 135.55 245.832C135.636 245.522 135.656 245.198 135.61 244.88V244.93Z" fill="black" />
                                          <path d="M120.93 242.59C120.74 242.595 120.555 242.654 120.398 242.76C120.24 242.867 120.116 243.016 120.04 243.19L119.76 242.08C119.969 241.864 120.224 241.698 120.506 241.594C120.789 241.49 121.091 241.451 121.39 241.48C121.604 241.466 121.818 241.5 122.017 241.579C122.216 241.658 122.395 241.781 122.541 241.938C122.686 242.095 122.795 242.283 122.859 242.487C122.923 242.692 122.94 242.908 122.91 243.12V244.31C122.91 244.31 123.96 241.53 126.04 241.57C126.253 241.555 126.466 241.584 126.667 241.656C126.867 241.728 127.05 241.841 127.205 241.987C127.359 242.134 127.481 242.312 127.563 242.509C127.645 242.705 127.685 242.917 127.68 243.13C127.68 244.29 126.61 245.88 122.87 246.24C122.85 246.437 122.874 246.636 122.94 246.823C123.006 247.01 123.113 247.18 123.252 247.32C123.392 247.461 123.561 247.569 123.747 247.636C123.934 247.704 124.133 247.729 124.33 247.71C124.768 247.743 125.205 247.627 125.568 247.38C125.931 247.133 126.2 246.77 126.33 246.35L127.87 246.83C127.572 247.49 127.074 248.039 126.445 248.398C125.816 248.758 125.09 248.909 124.37 248.83C122.3 248.83 121.31 247.66 121.37 246.23L121.45 243.37C121.45 242.88 121.34 242.59 120.93 242.59ZM125.42 242.67C124.1 242.67 122.86 244.85 122.87 245.37V245.81C125.67 245.15 126.07 244.02 126.08 243.41C126.096 243.319 126.092 243.225 126.068 243.135C126.043 243.045 126 242.962 125.939 242.892C125.879 242.821 125.804 242.764 125.719 242.726C125.635 242.688 125.543 242.669 125.45 242.67H125.42Z" fill="black" />
                                          <path d="M117.28 241.37L118.78 241.5L118.2 248.37H117.45L116.98 246.26C116.98 246.26 116.56 248.47 114.66 248.26C113.51 248.17 113.21 247.35 113.29 246.44C113.323 245.938 113.448 245.446 113.66 244.99L115.24 244.67C115.041 245.177 114.91 245.708 114.85 246.25C114.8 246.84 114.97 247.17 115.38 247.25C116.38 247.33 116.88 245.88 116.91 245.48L117.28 241.37Z" fill="black" />
                                          <path d="M105.98 240.9C105.784 240.882 105.588 240.918 105.411 241.005C105.235 241.091 105.086 241.224 104.98 241.39L104.85 240.25C105.086 240.063 105.362 239.931 105.656 239.865C105.951 239.799 106.256 239.801 106.55 239.87C106.764 239.883 106.972 239.943 107.159 240.047C107.346 240.151 107.508 240.296 107.632 240.471C107.755 240.645 107.838 240.845 107.874 241.057C107.911 241.268 107.899 241.484 107.84 241.69L107.67 242.87C107.67 242.87 109.07 240.25 111.12 240.56C111.333 240.57 111.541 240.625 111.732 240.721C111.922 240.817 112.09 240.951 112.225 241.116C112.36 241.282 112.458 241.473 112.514 241.679C112.57 241.884 112.582 242.099 112.55 242.31C112.38 243.46 111.13 244.91 107.38 244.77C107.332 244.964 107.328 245.166 107.369 245.362C107.409 245.558 107.493 245.742 107.614 245.901C107.735 246.06 107.889 246.19 108.067 246.282C108.244 246.373 108.44 246.424 108.64 246.43C109.068 246.514 109.513 246.451 109.901 246.252C110.289 246.052 110.599 245.727 110.78 245.33L112.25 246.01C111.869 246.625 111.303 247.104 110.632 247.377C109.962 247.65 109.223 247.704 108.52 247.53C106.52 247.23 105.63 245.98 105.88 244.53L106.39 241.66C106.5 241.27 106.38 240.96 105.98 240.9ZM110.42 241.57C109.11 241.37 107.59 243.4 107.54 243.92L107.45 244.37C110.32 244.08 110.85 243.01 110.95 242.37C110.975 242.284 110.982 242.195 110.97 242.106C110.958 242.018 110.927 241.933 110.88 241.857C110.833 241.782 110.77 241.717 110.696 241.668C110.622 241.618 110.538 241.585 110.45 241.57H110.42Z" fill="black" />
                                          <path d="M103.36 246.52L101.79 246.17L101.45 247.71L99.83 248.38L100.38 245.86L97.6 245.25L97.8 244.34L100.58 244.95L101.33 241.54C101.56 240.48 101.26 239.91 100.48 239.74C100.156 239.659 99.8155 239.676 99.5018 239.79C99.1881 239.904 98.9158 240.11 98.72 240.38L98.66 239.23C98.986 238.963 99.371 238.777 99.7832 238.688C100.195 238.599 100.623 238.61 101.03 238.72C101.366 238.758 101.69 238.872 101.975 239.055C102.259 239.238 102.498 239.484 102.672 239.775C102.847 240.065 102.951 240.391 102.979 240.729C103.006 241.066 102.955 241.405 102.83 241.72L102.05 245.29L103.56 245.62L103.36 246.52Z" fill="black" />
                                          <path d="M92.23 243.97C91.7319 243.875 91.2592 243.677 90.8428 243.388C90.4264 243.098 90.0756 242.724 89.8134 242.29C89.5511 241.856 89.3832 241.372 89.3206 240.869C89.258 240.366 89.3021 239.855 89.45 239.37C89.5832 238.881 89.818 238.426 90.1391 238.034C90.4602 237.643 90.8603 237.323 91.3134 237.097C91.7664 236.87 92.2621 236.742 92.7682 236.72C93.2742 236.698 93.7792 236.783 94.25 236.97C94.7476 237.067 95.2192 237.267 95.6341 237.558C96.0491 237.849 96.3981 238.224 96.6585 238.659C96.9188 239.094 97.0847 239.579 97.1452 240.082C97.2058 240.585 97.1598 241.096 97.01 241.58C96.874 242.064 96.6381 242.515 96.3176 242.903C95.9971 243.291 95.599 243.608 95.1489 243.833C94.6988 244.058 94.2067 244.186 93.704 244.21C93.2013 244.234 92.6993 244.152 92.23 243.97V243.97ZM93.96 238.04C93.6461 237.936 93.3135 237.9 92.9847 237.936C92.6559 237.971 92.3386 238.077 92.0542 238.246C91.7697 238.415 91.5249 238.643 91.3361 238.914C91.1474 239.186 91.0191 239.495 90.96 239.82C90.8386 240.122 90.7827 240.446 90.7959 240.772C90.8091 241.097 90.8913 241.415 91.0368 241.707C91.1824 241.998 91.3881 242.255 91.6403 242.46C91.8926 242.666 92.1856 242.816 92.5 242.9C92.8101 243.001 93.1382 243.034 93.4622 242.997C93.7862 242.96 94.0985 242.855 94.3781 242.687C94.6577 242.519 94.8981 242.293 95.083 242.025C95.2679 241.756 95.3931 241.451 95.45 241.13C95.5739 240.831 95.6331 240.509 95.6239 240.185C95.6147 239.861 95.5372 239.543 95.3965 239.252C95.2559 238.96 95.0552 238.701 94.8077 238.492C94.5601 238.284 94.2713 238.129 93.96 238.04V238.04Z" fill="black" />
                                          <path d="M80.2 242.37C79.7331 242.183 79.2515 242.036 78.76 241.93L82.44 232.93L83.82 233.5L80.2 242.37Z" fill="black" />
                                          <path d="M74.18 230.26C74.0017 230.193 73.8087 230.174 73.6208 230.206C73.4329 230.237 73.2567 230.318 73.11 230.44L73.32 229.31C73.601 229.199 73.9036 229.153 74.205 229.175C74.5063 229.198 74.7987 229.288 75.06 229.44C75.2606 229.515 75.4418 229.634 75.5905 229.788C75.7393 229.942 75.8517 230.128 75.9198 230.331C75.9878 230.534 76.0096 230.75 75.9837 230.962C75.9578 231.175 75.8848 231.379 75.77 231.56L75.26 232.63C75.26 232.63 77.36 230.53 79.26 231.41C79.4602 231.484 79.6428 231.599 79.7962 231.747C79.9497 231.895 80.0707 232.074 80.1516 232.271C80.2325 232.468 80.2714 232.681 80.266 232.894C80.2605 233.107 80.2108 233.317 80.12 233.51C79.62 234.51 78.01 235.58 74.46 234.37C74.3491 234.543 74.2794 234.739 74.2563 234.943C74.2333 235.147 74.2575 235.354 74.3271 235.547C74.3967 235.741 74.5098 235.915 74.6577 236.058C74.8056 236.201 74.9843 236.307 75.18 236.37C75.5693 236.568 76.0126 236.633 76.4424 236.556C76.8721 236.478 77.2647 236.262 77.56 235.94L78.77 237.01C78.2283 237.486 77.5507 237.78 76.8329 237.85C76.1151 237.92 75.3935 237.762 74.77 237.4C72.9 236.51 72.46 235.07 73.1 233.8L74.41 231.2C74.57 230.76 74.55 230.43 74.18 230.26ZM78.24 232.17C77.04 231.61 75.01 233.11 74.81 233.59L74.62 233.99C77.45 234.54 78.27 233.67 78.53 233.11C78.5851 233.032 78.6215 232.943 78.6365 232.849C78.6516 232.755 78.6448 232.659 78.6168 232.568C78.5888 232.478 78.5402 232.394 78.4749 232.325C78.4095 232.256 78.3292 232.203 78.24 232.17V232.17Z" fill="black" />
                                          <path d="M63.08 233.95C62.6489 233.693 62.1973 233.473 61.73 233.29L66.73 224.97L68.01 225.74L63.08 233.95Z" fill="black" />
                                          <path d="M62.13 221.78C62.5608 222.032 62.9345 222.371 63.2277 222.775C63.5208 223.179 63.727 223.639 63.8332 224.127C63.9394 224.615 63.9433 225.119 63.8446 225.609C63.746 226.098 63.5469 226.562 63.26 226.97C62.9926 227.394 62.638 227.756 62.2198 228.032C61.8016 228.308 61.3295 228.492 60.8347 228.571C60.34 228.651 59.834 228.624 59.3504 228.493C58.8668 228.361 58.4167 228.129 58.03 227.81C54.81 225.61 57.25 222.65 57.25 222.65L61.97 226.01C62.1748 225.753 62.3223 225.456 62.4025 225.137C62.4827 224.819 62.4938 224.487 62.4351 224.164C62.3763 223.841 62.2491 223.534 62.062 223.265C61.8748 222.995 61.632 222.768 61.35 222.6C61.1531 222.439 60.925 222.32 60.68 222.252C60.4351 222.184 60.1785 222.167 59.9267 222.202C59.6749 222.238 59.4332 222.326 59.2169 222.459C59.0006 222.593 58.8144 222.771 58.67 222.98L57.67 221.74C57.67 221.74 59.45 219.93 62.13 221.78ZM58.73 226.94C59.1431 227.254 59.6618 227.394 60.1767 227.333C60.6916 227.271 61.1625 227.012 61.49 226.61L58.03 224.37C57.7865 224.805 57.7233 225.317 57.8543 225.798C57.9852 226.279 58.2997 226.689 58.73 226.94Z" fill="black" />
                                          <path d="M57.45 220L50.56 220.85L54.39 223.85L53.81 224.59L48.61 220.48L49.27 219.64L56.11 218.76L52.33 215.76L52.91 215.02L58.11 219.13L57.45 220Z" fill="black" />
                                          <path d="M45.36 221.37L44.23 220.37L45.02 219.46L46.15 220.46L45.36 221.37ZM47.18 219.28L46.05 218.28L50.61 213.05L51.74 214.05L47.18 219.28Z" fill="black" />
                                          <path d="M41.4501 214.27C41.0567 213.952 40.7346 213.554 40.5048 213.103C40.275 212.652 40.1426 212.158 40.1162 211.652C40.0898 211.147 40.17 210.642 40.3516 210.169C40.5331 209.697 40.812 209.268 41.1701 208.91C41.5183 208.542 41.9402 208.251 42.4081 208.057C42.8761 207.863 43.3798 207.769 43.8863 207.782C44.3928 207.796 44.8909 207.915 45.3481 208.134C45.8053 208.352 46.2114 208.664 46.5401 209.05C46.9328 209.369 47.254 209.767 47.4829 210.219C47.7118 210.67 47.8432 211.164 47.8687 211.67C47.8942 212.175 47.8131 212.68 47.6307 213.152C47.4483 213.625 47.1687 214.053 46.8101 214.41C46.4619 214.776 46.0407 215.066 45.5736 215.259C45.1066 215.452 44.6042 215.545 44.0989 215.532C43.5937 215.519 43.0968 215.4 42.6405 215.182C42.1842 214.965 41.7786 214.654 41.4501 214.27ZM45.7601 209.85C45.5318 209.618 45.2568 209.437 44.9533 209.319C44.6498 209.201 44.3247 209.148 43.9995 209.165C43.6743 209.182 43.3565 209.268 43.0669 209.417C42.7774 209.566 42.5228 209.775 42.3201 210.03C42.0712 210.239 41.8696 210.499 41.7285 210.792C41.5875 211.085 41.5101 211.405 41.5016 211.73C41.4931 212.055 41.5536 212.378 41.6792 212.678C41.8048 212.978 41.9925 213.248 42.2301 213.47C42.4575 213.704 42.7323 213.886 43.0359 214.005C43.3396 214.124 43.6652 214.177 43.9909 214.16C44.3166 214.143 44.6348 214.056 44.9244 213.906C45.214 213.756 45.4682 213.546 45.6701 213.29C45.9216 213.083 46.1261 212.825 46.2699 212.532C46.4136 212.24 46.4934 211.92 46.5038 211.595C46.5142 211.269 46.455 210.945 46.3302 210.644C46.2054 210.343 46.0179 210.073 45.7801 209.85H45.7601Z" fill="black" />
                                          <path d="M35.55 203.77C34.67 204.53 34.72 205.44 35.55 206.38C35.7008 206.602 35.8968 206.79 36.1251 206.931C36.3534 207.073 36.609 207.164 36.8751 207.2C37.1412 207.236 37.4119 207.215 37.6695 207.139C37.927 207.064 38.1657 206.934 38.37 206.76L41.48 204.04L42.48 205.17L37.25 209.74L36.77 209.19L37.91 207.35C37.3516 207.73 36.666 207.875 36.0017 207.753C35.3374 207.631 34.7476 207.253 34.36 206.7C33.01 205.14 33.25 203.8 34.57 202.7L37.8 199.87L38.8 201L35.55 203.77Z" fill="black" />
                                          <path d="M34.56 195.37C34.8839 195.749 35.1257 196.191 35.2701 196.669C35.4146 197.146 35.4586 197.648 35.3994 198.143C35.3402 198.638 35.1791 199.116 34.9262 199.545C34.6733 199.975 34.3341 200.348 33.93 200.64C33.5391 200.954 33.0857 201.181 32.6003 201.306C32.1148 201.43 31.6083 201.451 31.1144 201.364C30.6206 201.278 30.1507 201.088 29.7361 200.806C29.3216 200.524 28.9718 200.158 28.71 199.73C26.39 196.6 29.71 194.59 29.71 194.59L33.08 199.32C33.3557 199.142 33.5906 198.908 33.7692 198.633C33.9478 198.358 34.066 198.048 34.1161 197.724C34.1662 197.4 34.147 197.069 34.0597 196.753C33.9725 196.437 33.8192 196.143 33.61 195.89C33.4758 195.674 33.2985 195.488 33.0892 195.343C32.8799 195.199 32.643 195.099 32.3933 195.05C32.1437 195.002 31.8867 195.005 31.6384 195.06C31.39 195.115 31.1557 195.22 30.95 195.37L30.38 193.86C30.38 193.86 32.59 192.68 34.56 195.37ZM29.66 199.13C29.956 199.552 30.3996 199.848 30.9033 199.959C31.4069 200.07 31.9338 199.989 32.38 199.73L29.85 196.37C29.6583 196.535 29.5012 196.736 29.3878 196.961C29.2744 197.187 29.2069 197.433 29.1892 197.685C29.1715 197.937 29.204 198.19 29.2848 198.43C29.3657 198.669 29.4932 198.89 29.66 199.08V199.13Z" fill="black" />
                                          <path d="M29.7299 191.93L28.83 190.58L30.3499 189.58L31.2399 190.93L29.7299 191.93Z" fill="black" />
                                          <path d="M18.3301 188.71L13.8 179.97L14.7401 179.48L16.6401 183.15L24.32 179.15L25.0501 180.56L17.37 184.56L19.2701 188.23L18.3301 188.71Z" fill="black" />
                                          <path d="M13.24 178.53L12.62 177.16L13.72 176.67L14.33 178.04L13.24 178.53ZM15.77 177.4L15.16 176.03L21.5 173.2L22.11 174.57L15.77 177.4Z" fill="black" />
                                          <path d="M7.82998 163.96C7.66579 163.485 7.4652 163.024 7.22998 162.58L16.46 159.58L16.72 160.39L15.3 161.39C15.8907 161.386 16.4655 161.582 16.9318 161.944C17.398 162.307 17.7285 162.816 17.87 163.39C18.0293 163.836 18.0935 164.31 18.0586 164.782C18.0236 165.254 17.8902 165.713 17.667 166.13C17.4437 166.548 17.1356 166.914 16.7623 167.205C16.3891 167.496 15.9591 167.705 15.5 167.82C15.0612 167.999 14.59 168.085 14.1162 168.072C13.6425 168.059 13.1767 167.947 12.7486 167.744C12.3205 167.54 11.9394 167.25 11.6297 166.891C11.32 166.533 11.0885 166.113 10.95 165.66C10.7684 165.181 10.7293 164.66 10.8376 164.16C10.9458 163.659 11.1967 163.201 11.56 162.84L7.82998 163.96ZM13.54 161.76C13.2242 161.833 12.9271 161.971 12.6676 162.165C12.408 162.359 12.1919 162.605 12.0328 162.887C11.8738 163.169 11.7754 163.482 11.7439 163.804C11.7124 164.127 11.7485 164.452 11.85 164.76C11.9422 165.075 12.1012 165.367 12.3162 165.616C12.5311 165.864 12.797 166.063 13.0958 166.2C13.3947 166.337 13.7194 166.407 14.0479 166.407C14.3765 166.407 14.7012 166.337 15 166.2C15.3171 166.131 15.6159 165.995 15.8762 165.801C16.1366 165.607 16.3526 165.36 16.5097 165.076C16.6669 164.792 16.7615 164.478 16.7875 164.154C16.8134 163.831 16.7699 163.505 16.66 163.2C16.5643 162.89 16.4045 162.604 16.1911 162.36C15.9776 162.117 15.7152 161.92 15.421 161.785C15.1267 161.649 14.8072 161.576 14.4831 161.572C14.159 161.568 13.8377 161.632 13.54 161.76V161.76Z" fill="black" />
                                          <path d="M15.25 154.14C15.4007 154.615 15.4511 155.117 15.3978 155.612C15.3446 156.108 15.1889 156.588 14.9407 157.02C14.6925 157.453 14.3572 157.829 13.956 158.125C13.5548 158.421 13.0964 158.63 12.61 158.74C12.1272 158.876 11.6208 158.908 11.1249 158.832C10.6289 158.757 10.1548 158.576 9.7344 158.302C9.31396 158.029 8.95687 157.668 8.68706 157.245C8.41726 156.822 8.24097 156.347 8.17002 155.85C7.25002 152.06 11.05 151.5 11.05 151.5L12.29 157.17C12.6199 157.117 12.9343 156.993 13.2117 156.807C13.4891 156.621 13.7229 156.377 13.8968 156.092C14.0708 155.807 14.1808 155.487 14.2194 155.155C14.2579 154.824 14.224 154.487 14.12 154.17C14.0825 153.918 13.9932 153.677 13.8578 153.462C13.7224 153.247 13.5438 153.062 13.3333 152.919C13.1228 152.776 12.885 152.678 12.6348 152.632C12.3847 152.586 12.1277 152.592 11.88 152.65L11.95 151.04C11.95 151.04 14.45 150.93 15.25 154.14ZM9.25002 155.68C9.34527 156.191 9.63541 156.644 10.0591 156.945C10.4827 157.245 11.0067 157.369 11.52 157.29L10.52 153.29C10.0378 153.442 9.63538 153.779 9.40107 154.227C9.16675 154.675 9.11963 155.197 9.27002 155.68H9.25002Z" fill="black" />
                                          <path d="M13.26 145.04L13.53 146.66L7.20001 150.96L6.92001 149.26L12.07 146.06L6.17001 144.65L5.89001 142.97L13.26 145.04Z" fill="black" />
                                          <path d="M3.00996 141.8L2.83997 140.3L4.03996 140.17L4.19997 141.66L3.00996 141.8ZM5.76997 141.49L5.59997 140L12.5 139.23L12.67 140.72L5.76997 141.49Z" fill="black" />
                                          <path d="M4.08997 130.37C4.25576 130.365 4.41884 130.326 4.56985 130.258C4.72086 130.189 4.85684 130.091 4.96997 129.97V127.42H5.89997L5.95997 129.9L11.96 129.76V131.26L5.95997 131.4V132.95H5.01997V130.95C4.9513 131.196 4.80726 131.414 4.60814 131.574C4.40901 131.733 4.16483 131.826 3.90997 131.84C3.07997 131.84 2.07997 131.13 2.02997 129.06C1.96997 126.12 4.02997 125.39 4.02997 125.39L4.51997 126.93C4.07314 127.049 3.68147 127.32 3.41163 127.695C3.14178 128.071 3.01044 128.528 3.03997 128.99C3.00997 129.83 3.44997 130.37 4.08997 130.37Z" fill="black" />
                                          <path d="M11.11 120.03C11.1077 119.839 11.0509 119.653 10.9464 119.494C10.8418 119.334 10.6939 119.208 10.52 119.13L11.64 118.86C11.8535 119.074 12.0158 119.334 12.1145 119.62C12.2132 119.905 12.2459 120.21 12.21 120.51C12.2218 120.724 12.1855 120.937 12.1037 121.135C12.0219 121.333 11.8967 121.51 11.7374 121.653C11.578 121.796 11.3886 121.902 11.1831 121.962C10.9776 122.022 10.7612 122.035 10.55 122H9.35998C9.35998 122 12.13 123.09 12.06 125.16C12.0725 125.373 12.0407 125.586 11.9664 125.787C11.8921 125.987 11.777 126.169 11.6284 126.322C11.4798 126.476 11.301 126.596 11.1033 126.677C10.9056 126.757 10.6934 126.796 10.48 126.79C9.30998 126.79 7.73998 125.67 7.47998 121.93C7.28273 121.905 7.08246 121.925 6.89387 121.988C6.70529 122.051 6.5332 122.155 6.39026 122.293C6.24731 122.431 6.13715 122.6 6.06788 122.786C5.99861 122.973 5.97199 123.172 5.98998 123.37C5.95306 123.805 6.06443 124.239 6.30596 124.602C6.54748 124.965 6.90491 125.236 7.31998 125.37L6.81998 126.9C6.1638 126.593 5.6217 126.086 5.27139 125.452C4.92108 124.818 4.78056 124.089 4.86998 123.37C4.93998 121.3 6.08998 120.37 7.50998 120.37L10.42 120.54C10.81 120.59 11.1 120.43 11.11 120.03ZM10.96 124.52C10.96 123.2 8.81998 121.92 8.29998 121.93H7.85998C8.47998 124.74 9.59998 125.15 10.22 125.17C10.3131 125.187 10.4088 125.182 10.5 125.157C10.5911 125.131 10.6754 125.086 10.7464 125.023C10.8175 124.961 10.8737 124.883 10.9106 124.796C10.9476 124.709 10.9645 124.615 10.96 124.52V124.52Z" fill="black" />
                                          <path d="M12.38 116.37L12.23 117.86L5.32996 117.19L5.39996 116.46L7.50996 116.03C7.50996 116.03 5.30996 115.57 5.50996 113.67C5.62996 112.53 6.44996 112.24 7.34996 112.33C7.85265 112.373 8.34368 112.505 8.79996 112.72L9.08996 114.31C8.59159 114.099 8.06671 113.958 7.52996 113.89C6.92996 113.84 6.59996 114 6.52996 114.41C6.43996 115.41 7.87996 115.93 8.26996 115.97L12.38 116.37Z" fill="black" />
                                          <path d="M13.61 108.29C13.5598 108.785 13.408 109.265 13.1641 109.699C12.9202 110.133 12.5894 110.512 12.1925 110.812C11.7955 111.112 11.3408 111.327 10.8569 111.444C10.373 111.561 9.87023 111.576 9.38001 111.49C8.87989 111.43 8.39831 111.265 7.9676 111.004C7.53689 110.742 7.16703 110.392 6.88287 109.976C6.5987 109.561 6.4068 109.089 6.32007 108.593C6.23334 108.097 6.25378 107.588 6.38001 107.1C7.02001 103.25 10.74 104.22 10.74 104.22L9.67001 109.92C9.98952 109.996 10.3214 110.003 10.6438 109.94C10.9662 109.878 11.2717 109.748 11.5402 109.559C11.8086 109.37 12.0339 109.126 12.2011 108.844C12.3683 108.561 12.4735 108.246 12.51 107.92C12.5715 107.673 12.5814 107.416 12.5391 107.166C12.4969 106.915 12.4034 106.676 12.2646 106.463C12.1257 106.25 11.9444 106.067 11.7319 105.928C11.5195 105.788 11.2806 105.693 11.03 105.65L11.73 104.19C11.73 104.19 14.14 105.03 13.61 108.29ZM7.50001 107.37C7.40045 107.876 7.49546 108.401 7.76609 108.841C8.03672 109.28 8.46306 109.601 8.96001 109.74L9.57001 105.63C9.31954 105.607 9.06692 105.633 8.82683 105.708C8.58673 105.784 8.36394 105.906 8.17138 106.067C7.97881 106.229 7.8203 106.428 7.70505 106.651C7.58981 106.875 7.52012 107.119 7.50001 107.37Z" fill="black" />
                                          <path d="M6.33997 95.14L6.73997 93.69L7.88997 94.01L7.49997 95.45L6.33997 95.14ZM9.01997 95.87L9.40997 94.42L16.1 96.25L15.71 97.69L9.01997 95.87Z" fill="black" />
                                          <path d="M18.74 81.55C18.74 81.55 20.9 82.87 19.74 85.94C19.5913 86.4162 19.3454 86.8564 19.018 87.2329C18.6905 87.6093 18.2886 87.9138 17.8376 88.1271C17.3866 88.3404 16.8962 88.4579 16.3975 88.4721C15.8988 88.4864 15.4025 88.3971 14.94 88.21C14.4734 88.0466 14.0466 87.7864 13.6875 87.4465C13.3284 87.1066 13.0452 86.6947 12.8564 86.2378C12.6676 85.7808 12.5774 85.2892 12.5918 84.795C12.6062 84.3007 12.7249 83.8152 12.94 83.37C13.94 80.72 15.9401 80.74 17.2201 81.16L17.0801 82.77C15.9601 82.23 14.57 82.24 13.98 83.77C13.47 85.1 14.17 86.19 15.55 86.72C15.8483 86.8703 16.1757 86.9541 16.5095 86.9657C16.8433 86.9773 17.1756 86.9164 17.4836 86.7871C17.7916 86.6578 18.0679 86.4633 18.2934 86.2169C18.519 85.9705 18.6884 85.6782 18.79 85.36C18.8995 85.1303 18.9603 84.8805 18.9688 84.6261C18.9773 84.3718 18.9333 84.1185 18.8394 83.882C18.7456 83.6455 18.604 83.4309 18.4234 83.2516C18.2428 83.0723 18.0272 82.9322 17.79 82.84L18.74 81.55Z" fill="black" />
                                          <path d="M23.54 74.57C23.6082 74.3918 23.6274 74.1986 23.5957 74.0105C23.5641 73.8224 23.4827 73.6461 23.3601 73.5L24.49 73.71C24.5997 73.9896 24.6449 74.2904 24.6224 74.5898C24.5999 74.8893 24.5102 75.1799 24.3601 75.44C24.2841 75.6412 24.164 75.8228 24.0087 75.9715C23.8534 76.1202 23.6667 76.2323 23.4625 76.2994C23.2582 76.3665 23.0414 76.3871 22.8282 76.3595C22.6149 76.3319 22.4105 76.2569 22.23 76.14L21.1601 75.63C21.1601 75.63 23.26 77.74 22.37 79.63C22.2961 79.8301 22.1814 80.0128 22.0332 80.1662C21.885 80.3197 21.7065 80.4407 21.5091 80.5216C21.3116 80.6024 21.0995 80.6414 20.8862 80.6359C20.6729 80.6305 20.4631 80.5808 20.2701 80.49C19.2701 79.99 18.2 78.37 19.43 74.82C19.2587 74.7187 19.0669 74.6568 18.8686 74.6389C18.6704 74.621 18.4706 74.6474 18.2839 74.7164C18.0971 74.7854 17.9281 74.8951 17.789 75.0375C17.65 75.18 17.5444 75.3516 17.48 75.54C17.2808 75.9282 17.2141 76.3709 17.2899 76.8006C17.3657 77.2303 17.58 77.6234 17.9 77.92L16.82 79.12C16.3457 78.5768 16.0546 77.8979 15.9882 77.1798C15.9218 76.4618 16.0834 75.741 16.45 75.12C17.35 73.25 18.7801 72.81 20.0501 73.46L22.65 74.78C23.04 74.95 23.37 74.93 23.54 74.57ZM21.6101 78.62C22.1801 77.42 20.69 75.38 20.2 75.18L19.81 74.99C19.25 77.81 20.12 78.64 20.67 78.9C20.7475 78.9533 20.836 78.9886 20.9289 79.0033C21.0218 79.018 21.1169 79.0116 21.207 78.9848C21.2972 78.9579 21.3801 78.9112 21.4499 78.8481C21.5196 78.785 21.5744 78.707 21.6101 78.62V78.62Z" fill="black" />
                                          <path d="M24.1701 73.08L22.2901 66.37L19.8801 70.61L19.0601 70.15L22.3301 64.38L23.2601 64.91L25.1801 71.53L27.5501 67.37L28.3701 67.83L25.1001 73.59L24.1701 73.08Z" fill="black" />
                                          <path d="M27.92 66.54L26.45 59.75L23.78 63.82L22.99 63.31L26.63 57.77L27.52 58.36L29 65.09L31.65 61.09L32.43 61.6L28.79 67.14L27.92 66.54Z" fill="black" />
                                          <path d="M25.53 54.63L26.41 53.42L27.41 54.13L26.53 55.34L25.53 54.63ZM27.77 56.27L28.65 55.05L34.26 59.14L33.38 60.35L27.77 56.27Z" fill="black" />
                                          <path d="M33.08 49.37L34.14 48.16L32.96 47.16L33.16 45.42L35.09 47.12L36.98 44.98L37.68 45.6L35.8 47.74L38.42 50.04C39.24 50.76 39.87 50.77 40.42 50.17C40.6481 49.9263 40.7985 49.6202 40.8519 49.2907C40.9053 48.9612 40.8594 48.6233 40.72 48.32L41.72 48.83C41.7962 49.2429 41.7724 49.6682 41.6506 50.07C41.5288 50.4719 41.3126 50.8388 41.02 51.14C40.8253 51.4143 40.5703 51.6403 40.2746 51.8006C39.9789 51.961 39.6504 52.0514 39.3144 52.0649C38.9783 52.0784 38.6436 52.0147 38.336 51.8786C38.0284 51.7425 37.756 51.5378 37.54 51.28L34.8 48.86L33.8 50.03L33.08 49.37Z" fill="black" />
                                          <path d="M42.37 45.46C43.19 46.29 44.09 46.18 44.98 45.3C45.1914 45.1355 45.3657 44.9283 45.4913 44.6917C45.617 44.4552 45.6912 44.1948 45.7092 43.9276C45.7271 43.6604 45.6883 43.3923 45.5954 43.1411C45.5025 42.8899 45.3575 42.6612 45.17 42.47L42.27 39.54L43.33 38.48L48.22 43.4L47.71 43.92L45.8 42.92C46.2125 43.4537 46.3986 44.1283 46.3182 44.7981C46.2377 45.4678 45.8972 46.0791 45.37 46.5C43.91 47.95 42.56 47.8 41.37 46.56L38.37 43.51L39.43 42.45L42.37 45.46Z" fill="black" />
                                          <path d="M47.88 34.27C48.2335 33.9076 48.6596 33.6238 49.1302 33.4372C49.6009 33.2506 50.1056 33.1653 50.6114 33.187C51.1173 33.2087 51.6128 33.3369 52.0658 33.5631C52.5187 33.7894 52.9188 34.1086 53.24 34.5C53.5729 34.8817 53.8217 35.3291 53.9704 35.8132C54.119 36.2973 54.1642 36.8072 54.1029 37.3099C54.0416 37.8125 53.8752 38.2967 53.6146 38.7309C53.3539 39.1651 53.0048 39.5395 52.59 39.83C52.2358 40.1919 51.8092 40.4749 51.3382 40.6607C50.8672 40.8465 50.3622 40.9308 49.8564 40.9082C49.3505 40.8855 48.8551 40.7564 48.4026 40.5293C47.95 40.3021 47.5505 39.9821 47.23 39.59C46.8974 39.2092 46.6487 38.7627 46.5001 38.2793C46.3515 37.796 46.3064 37.2869 46.3678 36.785C46.4291 36.2831 46.5954 35.7997 46.856 35.3664C47.1166 34.9331 47.4655 34.5595 47.88 34.27V34.27ZM51.88 38.98C52.133 38.7741 52.3396 38.5169 52.4861 38.2254C52.6325 37.9339 52.7156 37.6146 52.7297 37.2887C52.7439 36.9628 52.6889 36.6375 52.5683 36.3344C52.4477 36.0313 52.2642 35.7571 52.03 35.53C51.8445 35.2628 51.6047 35.0378 51.3262 34.8697C51.0477 34.7016 50.7369 34.5942 50.414 34.5546C50.0912 34.5149 49.7636 34.5439 49.4527 34.6396C49.1418 34.7353 48.8547 34.8956 48.61 35.11C48.3544 35.3131 48.1454 35.5687 47.9971 35.8595C47.8489 36.1504 47.7648 36.4697 47.7505 36.7958C47.7363 37.1219 47.7923 37.4474 47.9147 37.75C48.0371 38.0526 48.2231 38.3254 48.46 38.55C48.6427 38.819 48.8806 39.046 49.1578 39.2159C49.435 39.3858 49.7453 39.4947 50.0679 39.5354C50.3905 39.576 50.718 39.5476 51.0288 39.4518C51.3395 39.356 51.6263 39.1952 51.87 38.98H51.88Z" fill="black" />
                                          <path d="M51.0601 28.37L52.2601 27.47L52.9801 28.42L51.7801 29.33L51.0601 28.37ZM52.7301 30.59L53.9301 29.68L58.1101 35.22L56.9101 36.12L52.7301 30.59Z" fill="black" />
                                          <path d="M55.45 25.14L59.22 30.69L58.1 31.48L54.1 26.07L55.45 25.14ZM58.68 32.53L60.01 31.59L61.06 33.07L59.73 34.01L58.68 32.53Z" fill="black" />
                                    </g>
                                    <defs>
                                          <clipPath id="clip0">
                                                <rect width="248" height="245.82" fill="white" transform="translate(2 3)" />
                                          </clipPath>
                                    </defs>
                              </svg>

                        </svg>
                  </div>

                  <div className="pixelated-penis">
                        <svg width="105" height="131" viewBox="0 0 105 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M72.2815 0.0906067H78.7849V6.59402H72.2815V0.0906067ZM78.785 0.0906067H85.2884V6.59402H78.785V0.0906067ZM91.7919 0.0906067H85.2885V6.59402H91.7919V0.0906067Z"
                                    fill="#030303" />
                              <rect x="78.7853" y="6.91916" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.2" />
                              <rect x="78.7849" y="13.4226" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="91.7917" y="6.91916" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.8" />
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M98.2952 13.4226H91.7917V19.926H98.2952V13.4226ZM98.295 19.926H91.7916V26.4294H98.295V19.926Z"
                                    fill="#030303" />
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M65.7781 13.4226H59.2747V19.926H65.7781V13.4226ZM59.2748 19.926H65.7782V26.4294H59.2748V19.926ZM65.7782 19.926H72.2816V26.4294H65.7782V19.926Z"
                                    fill="#030303" />
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M72.2815 26.4294H78.7849V32.9328H72.2815V26.4294ZM78.785 26.4294H85.2884V32.9328H78.785V26.4294ZM91.7919 26.4294H85.2885V32.9328H91.7919V26.4294Z"
                                    fill="#030303" />
                              <rect x="72.2815" y="52.443" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="65.7781" y="65.4499" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="52.7712" y="32.9328" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="46.2679" y="45.9396" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="39.7645" y="52.443" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.8" />
                              <rect x="72.2815" y="58.9464" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.2" />
                              <rect x="85.2883" y="32.9328" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.9" />
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M85.2887 39.4362H78.7853V45.9396H85.2887V39.4362ZM85.2882 45.9396H78.7848V52.443H85.2882V45.9396Z"
                                    fill="#030303" fillOpacity="0.7" />
                              <rect x="59.2747" y="78.4566" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="59.2747" y="104.47" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="65.7781" y="84.9601" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="65.7781" y="110.974" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="59.2747" y="117.477" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="52.7712" y="117.477" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="52.7712" y="123.98" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="46.2681" y="117.477" width="6.50341" height="6.50341" fill="#030303" />
                              <rect x="39.7646" y="117.477" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.5" />
                              <rect x="46.2681" y="110.974" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.7" />
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M39.7646 104.47H33.2612V110.974H39.7646V104.47ZM39.7646 110.974H33.2612V117.477H39.7646V110.974Z"
                                    fill="#030303" />
                              <rect x="33.2612" y="65.4499" width="6.50341" height="6.50341" fill="#030303" />
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M20.2543 58.9464H13.751V65.4499H20.2543H20.2544H26.7577V58.9464H20.2544H20.2543ZM26.7577 58.9464H33.2611V65.4499H26.7577V58.9464Z"
                                    fill="#030303" />
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M20.2543 97.9668H13.751V104.47H20.2543H20.2544H26.7577V97.9668H20.2544H20.2543ZM26.7577 97.9668H33.2611V104.47H26.7577V97.9668Z"
                                    fill="#030303" />
                              <rect x="13.751" y="65.4499" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.2" />
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M0.744141 71.9532H7.24755V78.4566H0.744141V71.9532ZM0.744019 78.4566H7.24743V84.96H0.744019V78.4566ZM7.24743 84.9601H0.744019V91.4635H7.24743V84.9601Z"
                                    fill="#030303" />
                              <rect x="7.24756" y="78.4566" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.2" />
                              <rect x="0.744141" y="91.4635" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.2" />
                              <rect x="20.2544" y="84.9601" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.2" />
                              <rect x="72.2815" y="97.9668" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.2" />
                              <rect x="13.751" y="78.4566" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.5" />
                              <rect x="7.24756" y="91.4635" width="6.50341" height="6.50341" fill="#030303" fillOpacity="0.5" />
                              <rect x="26.7578" y="71.9532" width="6.50341" height="6.50341" fill="#030303" />
                        </svg>
                  </div>
            </div>
      )
            ;
}

export default PixelatedDick;
