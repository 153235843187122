import React from 'react';
import CopyToClipboard from '@uiw/react-copy-to-clipboard';
import CloseButton from "../images/closeButton.png";
import { HASHES, DEFINITIONS } from '../utils/constants';

interface ShareSheetProps {
  hash: string
  onDidTapDismiss: () => void;
}

class ShareSheet extends React.Component<ShareSheetProps> {
  constructor(props: ShareSheetProps) {
    super(props)

    this.url = `https://fattiicazzituoi.it/${this.props.hash}/`
    this.description = DEFINITIONS[HASHES.indexOf(this.props.hash, 0)]
  }

  // The URL associated with the sentence to share.
  url: string

  // The description related to the url.
  description: string

  handleOnDidTapDismiss = () => {
    this.props.onDidTapDismiss();
  }

  handleOnDidTapTwitter = () => {
    this.props.onDidTapDismiss();
    window.open(`https://twitter.com/intent/tweet?url=${this.url}&hashtags=fattiicazzituoi&text=${this.description}&via=facatupuntoit`)
  }

  handleOnDidTapFacebook = () => {
    this.props.onDidTapDismiss();
    window.location.href = "/facebook-ban"
  }

  render() {
    return(
      <div id="share-sheet-container" className="center-screen">
        <button className="transparent-button share-sheet-close-button" onClick={this.handleOnDidTapDismiss}>
          <img className="action-button" src={CloseButton} alt="A button to close the modal"/>
        </button>

        <span className="share-sheet-title">Convidi frase</span>

        <div className="action-buttons-container title-sheet-action-buttons">

        <button className="transparent-button" onClick={this.handleOnDidTapFacebook}>
            <div className="action-button-with-text">
              <span>Facebook</span>
            </div>
          </button>

          <button className="transparent-button" onClick={this.handleOnDidTapTwitter}>
            <div className="action-button-with-text">
              <span>Twitter</span>
            </div>
          </button>

          <button className="transparent-button">
            <div className="action-button-with-text">
              <CopyToClipboard
                  text={this.url}
                  onClick={ this.handleOnDidTapDismiss }
              >
                Copia Link
              </CopyToClipboard>
            </div>
          </button>
        </div>
      </div>

    )
  }
}

export default ShareSheet;