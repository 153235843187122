import { createRoot } from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './components/Landing';
import Origins from './components/Origins';
import FacebookBan from './components/FacebookBan';
import { HASHES } from "./utils/constants";
import PageNotFound from './components/PageNotFound';
import { Suspense } from 'react';

createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" Component={Landing} />
        <Route path={`/${HASHES[0].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[0]} />} />
        <Route path={`/${HASHES[1].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[1]} />} />
        <Route path={`/${HASHES[2].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[2]} />} />
        <Route path={`/${HASHES[3].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[3]} />} />
        <Route path={`/${HASHES[4].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[4]} />} />
        <Route path={`/${HASHES[5].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[5]} />} />
        <Route path={`/${HASHES[6].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[6]} />} />
        <Route path={`/${HASHES[7].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[7]} />} />
        <Route path={`/${HASHES[8].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[8]} />} />
        <Route path={`/${HASHES[9].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[9]} />} />
        <Route path={`/${HASHES[10].toLocaleLowerCase()}/`} Component={(props) => <Landing {...props} hash={HASHES[10]} />} />
        <Route path="/origini" Component={Origins} />
        <Route path="/facebook-ban" Component={FacebookBan} />
        <Route path="*" Component={PageNotFound} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);
