import React from 'react';

import Footer from "./Footer";
import Navbar from "./NavigationBar";
import ShareSheet from "./ShareSheet";

import PixelatedDick from "./PixelatedDick";
import BackgroundDick from "../images/backgroundDick.png";
import ShuffleButton from "../images/shuffleButton.png";
import ShareButton from "../images/shareButton.png";
import { DEFINITIONS, HASHES } from "../utils/constants";

interface LandingProps {
  hash?: string;
}

interface LandingState {
  definition: string;
  hash: string;
  isModalVisible: boolean;
}


class Landing extends React.Component<LandingProps, LandingState> {
  constructor(props: LandingProps) {
    super(props);

    this.state = {
      definition: '',
      hash: props.hash ?? '',
      isModalVisible: false
    }
  }

  // This syntax ensures `this` is bound within handleClick.
  // Warning: this is *experimental* syntax.
  getRandomDefinition = () => {
    var isDifferent = false
    var newDefinition: string
    var newHash: string

    // Make sure every random is different from the previous one.
    do {
      const randomIndex = Math.floor(Math.random() * DEFINITIONS.length);
      newDefinition = DEFINITIONS[randomIndex];
      newHash = HASHES[randomIndex];

      // First time the state is undefined.
      isDifferent = typeof this.state === 'undefined' ? true : newDefinition !== this.state.definition
    } while (!isDifferent)

    return {
      newDefinition,
      newHash,
    }
  }

  shuffleDefinition = () => {
    const definitionAndHash = this.getRandomDefinition();

    this.setState({
      definition: definitionAndHash.newDefinition,
      hash: definitionAndHash.newHash,
      },
    )
  }

  dismissModal = () => {
    this.setState({isModalVisible: false})
  }

  showModal = () => {
    this.setState({isModalVisible: true})
  }

  componentDidMount() {
    const pathIndex = HASHES.indexOf(this.state.hash, 0);
    const definitionAndHash = this.getRandomDefinition();

    if (this.state.hash && pathIndex) {
      this.setState({
        definition: DEFINITIONS[pathIndex],
        hash: HASHES[pathIndex],
        isModalVisible: false
      })
    } else {
      this.setState({
        definition: definitionAndHash.newDefinition,
        hash: definitionAndHash.newHash,
        isModalVisible: false
      })
    }
  }

  render() {
    return (
      <div id="landing">
      <img src={BackgroundDick} alt="a penis outline" className="background-penis"/>
      <Navbar showBack={false}/>

      <div className="box full-page-wrapper">
        <h1>Fatti i cazzi tuoi</h1>
        <span className="phonetics">/fàt-ti-i-càz-zi-tuòi/</span>
        <div className="definition-container">
          <span className="definition"> {this.state.definition} </span>
        </div>

        <div className="action-buttons-container">
        <button className="transparent-button" onClick={this.shuffleDefinition}>
          <div className="action-button-with-text">
            <img src={ShuffleButton} alt="A button to shuffle the definition" className="action-button"/>
            <span>CAMBIA DEFINIZIONE</span>
          </div>
        </button>

        <button className="transparent-button" onClick={this.showModal}>
          <div className="action-button-with-text">
            <img src={ShareButton} alt="A button to share the definition" className="action-button"/>
            <span>CONDIVIDI</span>
          </div>
        </button>
      </div>
      </div>

      {this.state.isModalVisible && <ShareSheet hash={this.state.hash} onDidTapDismiss={this.dismissModal}/>}
      <PixelatedDick />
      <Footer />
    </div>
    )
  }
}

export default Landing;