import React from 'react';

import PixelatedDick from "./PixelatedDick";
import Footer from "./Footer";
import Navbar from "./NavigationBar";

class Origins extends React.Component {
  render() {
    return (
      <div id="origins" className="article">
        <Navbar showBack/>
        <div className="content-wrapper full-page-wrapper">
          <h1>
            Come cazzo è nata l'idea
          </h1>

          <p>
            Se ti stai chiedendo perché e come è nato fattiicazzituoi.it sei capitato nel posto giusto. 
            Il mio nome è Firas (classico nome Milanese), e questa è la storia di come la mia vita è cambiata.

            <br />
            <br />

            È venerdì 25 giugno 2021. Al mio risveglio mi accorgo subito che c’è qualcosa che non va: sento dolore alla pancia 
            e mi precipito in bagno (ci siamo capiti, no?).
            Decido saggiamente di lavorare da casa, lo comunico al mio team e vado avanti con la mia giornata.

            <br />
            <br />

            Passate poche ore inizio a sentirmi meglio e dato che è venerdì propongo ai miei colleghi di trovarci a bere dopo il lavoro. 
            A questo punto Daniela, un membro del mio team, sceglie di non farsi i cazzi propri rispondendo "ma non hai detto che non stai bene?”, 
            e l’unica risposta che avrei voluto darle in quel momento era "ma perché non ti fai i cazzi tuoi?".

            <br />
            <br />

            Purtroppo non è molto diplomatico rispondere in questo modo, soprattutto se siamo in un ambiente lavorativo.
            In quell’istante, l’illuminazione: e se esistesse un sito per insegnare alle persone a farsi i cazzi propri senza offenderle?

            <br />
            <br />

            Allora ho chiamato il mio amico Vathsav, che con sofisticate pratiche di design thinking 
            e avanzate tecnologie di sviluppo (ma soprattutto un bel po’ di canne) mi hanno aiutato a creare questo sito.

            <br />
            <br />

            Ma non finisce qui!
            Abbiamo ancora tante idee e sorprese. Questa è solo la punta dell’iceberg, la versione “safe”, vanilla. Aspetta e vedrai.
            Ah, e se per caso ti stessi chiedendo quali idee e sorprese abbiamo in testa, o come sarà la versione NSFW… 
            si vede che non hai ancora imparato a farti i cazzi tuoi!
          </p>
        </div>

        <PixelatedDick />
        <Footer />  
      </div>
    )
  }
}

export default Origins;