import { useNavigate } from 'react-router-dom';

import BackButton from '../images/backButton.png';

function Navbar(prop: { showBack: Boolean }) {
  const navigate = useNavigate()
  
  return (
    <div role="navigation" id="navigation">
      {
        prop.showBack && 
        <input id="back-button" type="image" src={BackButton} onClick={() => navigate("/")} alt="back button"/>
      }

      <ul>
        <li><a href="/origini">Origini</a></li>
      </ul>
    </div>
  );
}

export default Navbar;