import React, { useState } from 'react';
import { IGif } from "@giphy/js-types";
import { Gif } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { useAsync } from "react-async-hook";

import PixelatedDick from "./PixelatedDick";
import Footer from "./Footer";

const giphyFetch = new GiphyFetch("sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh");
const GIFS = [
    "KDMHTW9mx2Z2g", 
    "G9SazpPDVs6SaHJArP", 
    "EgO2x020RTAuk", 
    "zH3xFFRJiMmNa",
    "enUksezaa18IYPeyQQ",
    "U6btA7Oenk5sk",
    "vpEziJAczOu7S",
    "gEUhthlCl3K0g"
]

function GifDemo() {
    const [gif, setGif] = useState<IGif | null>(null);
    useAsync(async () => {
        const { data } = await giphyFetch.gif(GIFS[Math.floor(Math.random() * GIFS.length)])
        setGif(data);
    }, []);
    return gif && <Gif gif={gif} width={400} />;
}

class PageNotFound extends React.Component {
    render() {
        return (
            <div>
                <div id="content-404">
                    <p>404 - Non ho trovato un cazzo</p>
                    
                    <span id="gif">
                    <GifDemo />
                    </span>
                    
                    <p><a href="/">TORNA ALLA HOMEPAGE</a></p>
                </div>
                <PixelatedDick />
                <Footer />
            </div>
        )
    }
}

export default PageNotFound;