import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer id="footer">
        {/* <PixelatedDick /> */}
        
        <p id="creators">
          <span>Creato da</span> &nbsp;
          <a className="social-link" href="https://www.theinkedengineer.com" target="_blank" rel="noopener noreferrer">The Inked Engineer</a> & 
          <a className="social-link" href="https://www.vathsav.com" target="_blank" rel="noopener noreferrer"> Vathsav</a> 
        </p>
      </footer>
    );
  }
}

export default Footer;