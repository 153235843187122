import React from 'react';

import PixelatedDick from "./PixelatedDick";
import Footer from "./Footer";
import Navbar from "./NavigationBar";

class FacebookBan extends React.Component {
  render() {
    return (
      <div id="facebook-ban" className="article">
        <Navbar showBack/>
        <div className="content-wrapper full-page-wrapper">
          <h1>
            Facebook c'ha bannato
          </h1>

          <p>
            Non ho un account Facebook da più di 7 anni ormai, 
            cosi ho sempre la giustificazione quando dimentico il compleanno degli amici. 
            Quindi tranne quando c'è qualche scandalo riguardo i dati degli utenti ignoranti 
            che ancora non capiscono che nun se pò fidare di Facebook, sta piattaforma m’arimbarza.

            <br />
            <br />

            Però mentre sviluppavo la nuova feature di condivisione su social, 
            mi sono reso conto testando, che il nostro link è bannato su Facebook perché "viola gli Standard della community". 
            La stessa community demmerda piena di omofobi, razzisti, vegani, nazisti, terroristi, 
            gente che mette il ketchup sulla pasta e tanta altra gente di qualità.

            <br />
            <br />

            First reaction <a href="https://www.youtube.com/watch?v=9zwQByP49Ac" target="_blank" rel="noopener noreferrer">SHOCK!</a> seguito da "Oh ma che è stammerda man?"™

            <br />
            <br />

            Una serie di persone, fiji de 'na mignotta, particolarmente sensibili ed omobofi (dato che il nostro logo è err cazzo 💜) 
            hanno segnalato il nostro sito come abusivo (pensa te se vedessero le future versioni). 
            Al posto di farsi i propri cazzi tra l'altro, ma vabbè!

            <br />
            <br />

            Comunque, long story short, non possiamo diffondere questo messaggio su Facebook o Instagram, 
            quindi fate la stessa cosa che fate ogni volta che i server di Facebook vanno a puttane, 
            e condividete il nostro link su Twitter, Telegram, Pornhub e tutti i siti belli del mondo.
          </p>
        </div>

        <PixelatedDick />
        <Footer />  
      </div>
    )
  }
}

export default FacebookBan;