export const DEFINITIONS = [
    "Non dire sempre la tua. Anzi mai.",
    "Ti ho chiesto un consiglio? Non mi pare.",
    "Non immischiarti se non espressamente richiesto.",
    "Puoi anche tacere, non mi offendo.",
    "Non divulgare ciò che sai, non sei Wikipedia.",
    "Nella vita vince chi tace",
    "Muori dalla curiosità? RIP.",
    "Fatti una vita (oltre ai cazzi tuoi).",
    "Ti confido un segreto. Scherzavo.",
    "Non essere come Marta M.",
    "Ignuno pensi a li fattacci sua"
];

export const HASHES = [
    "chie0",
    "aah9i",
    "eem0p",
    "poog0",
    "foo1a",
    "em3ik",
    "uw2Oh",
    "coz7a",
    "lab7h",
    "ied2g",
    "roma1"
];
